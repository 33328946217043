import Pagination from "../../components/commons/Table/Pagination";
import { useStyles } from "../../helper/Theme";
import { useNavigate } from "react-router-dom";
import {
  Paper,
  Table,
  TableCell,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Stack,
  TextField,
  Autocomplete,
} from "@mui/material";
import { Unknown } from "../../helper/Constant";
import { DateRange, Input } from "../../components/commons";
import { personList } from "../../helper/Helper";
import { useState, useEffect } from "react";
import { getCloseDayApiHandler } from "../../Service/CloseDay.service";
import { newPersonListHandler } from "../../Service/personList.service";
import { TableSkeleton } from "../../components/commons/Table";
import NoRecordFound from "../../components/commons/NoRecordFound";
import moment from "moment/moment";
import SubHeader from "../../components/commons/SubHeader";

export default function Index() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [closeDayData, setCloseDatData] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [page, setPage] = useState(1);
  const [personType, setPersonType] = useState("");

  const [personList, setPersonList] = useState([]);
  const [selectPerson, setSelectPerson] = useState("");

  const getAllCloseDataHandler = async (
    startDate,
    endDate,
    page,
    personType
  ) => {
    try {
      setLoading(true);
      const { count, data, message, success, totalPage } =
        await getCloseDayApiHandler(startDate, endDate, page, personType);
      if (success) {
        setDataCount(count);
        setCloseDatData(data);
      } else {
        setDataCount(0);
        setCloseDatData([]);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllCloseDataHandler(startDate, endDate, page, personType);
    getPersonListHandler();
  }, [startDate, endDate, page, personType]);

  const getPersonListHandler = async () => {
    try {
      const { count, data, message, success, totalPage } =
        await newPersonListHandler();
      if (success) {
        const personData =
          data &&
          data.map((res) => {
            console.log("rreess", res);
            return {
              label: res.username,
              id: res.id,
            };
          });
        setPersonList(personData);
      } else {
        setPersonList([]);
      }
    } catch (err) {
      console.error("error", err);
    }
  };

  const pageChangeHandler = (data) => {
    setPage(data);
  };

  const dataRanageHandler = (value) => {
    setStartDate(value.formDate);
    setEndDate(value.toDate);
  };

  return (
    <>
      <Stack flexDirection={"row"} gap={2} mb={2}>
        {/* <Autocomplete
          freeSolo
          value={selectPerson}
          sx={{ width: 300, backgroundColor: "#fff", borderRadius: "10px" }}
          options={personList}
          onChange={(event, value) => {
            if (value) {
              setSelectPerson(value);
              setPersonType(value?.id ? value?.id : "");
            } else {
              setSelectPerson("");
              setPersonType(value?.id);
            }
          }}
          renderInput={(params) => (
            <TextField {...params} placeholder={"Select person"} />
          )}
        /> */}
        {/* <DateRange dataHandler={dataRanageHandler} /> */}
        <Input
          placeholder="Select Date"
          type="date"
          onChange={(event) => setStartDate(event.target.value)}
        />
      </Stack>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow className="MuiReportTable-root">
              <TableCell>SFI</TableCell>
              <TableCell>Image</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Entry</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>Comment</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableSkeleton rowsNum={10} colsNum={6} />
            ) : closeDayData && closeDayData.length > 0 ? (
              closeDayData.map((response, index) => {
                const {
                  alert_image,
                  alert_type,
                  alert_type_name,
                  blacklisted_person,
                  created_at,
                  device,
                  device_name,
                  image,
                  entry_time,
                  exit_time,
                  guardian_profile_image,
                  id,
                  location,
                  location_name,
                  master_guardian_profile_image,
                  profile_image,
                  catgory,
                  comment,
                  student,
                  date,
                  entry,
                  non_exited,
                  usernames,
                } = response;
                return (
                  <TableRow
                    key={index}
                    className="MuiReportTable-root"
                    sx={{ cursor: "pointer" }}
                  >
                    <TableCell>{usernames ? usernames : "-"}</TableCell>
                    <TableCell>
                      <img
                        src={image ? image : ""}
                        alt=""
                        width={100}
                        height={100}
                      />
                    </TableCell>
                    <TableCell>{moment(date).format("DD-MM-YYYY")}</TableCell>
                    <TableCell>{entry ? entry : "-"}</TableCell>
                    <TableCell>{catgory ? catgory : "-"}</TableCell>
                    <TableCell>{comment ? comment : "-"}</TableCell>
                  </TableRow>
                );
              })
            ) : (
              <NoRecordFound colSpan={6} />
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        pageNumber={page}
        count={dataCount}
        pageChangeHandler={pageChangeHandler}
      />
    </>
  );
}
