import { get } from "../Web.request";

export const getExceptionApiListHandler = (
  startDate,
  endDate,
  page,
  personType,
  roomNo,
  listFor
) => {
  return get(
    `${process.env.REACT_APP_API_URL}/users/exception_attendance_data?page=${page}&start_date=${startDate}&end_date=${endDate}&person_name=${personType ? personType : ''}&standard_name=${roomNo}&alert_type_name=${listFor}`
  );
};


export const getRoomDropDownList = () => {
  return get(
    `${process.env.REACT_APP_API_URL}/users/standard`
  );
};