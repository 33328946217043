import React, { useState } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";
import { Stack } from "@mui/material";
import { FaRegCalendar } from "react-icons/fa6";

export default function DateRange(props) {
  const {
    dataHandler
  } = props;

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [days, setDays] = useState("");
  const [weekDayStartName, setWeekDayStartName] = useState("");
  const [monthStartName, setMonthStartName] = useState("");
  const [weekDayEndName, setWeekDayEndName] = useState("");
  const [monthEndName, setMonthEndName] = useState("");

  const range = {
    Today: [moment(), moment()],
    Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
    "Last 7 Days": [moment().subtract(6, "days"), moment()],
    "Last 30 Days": [moment().subtract(29, "days"), moment()],
    "This Month": [moment().startOf("month"), moment().endOf("month")],
    "Last Month": [
      moment().subtract(1, "month").startOf("month"),
      moment().subtract(1, "month").endOf("month"),
    ],
    "Last Year": [
      moment().subtract(1, "year").startOf("year"),
      moment().subtract(1, "year").endOf("year"),
    ],
  };

  const handleEvent = (event, picker) => {
    const diffDays = picker.endDate.diff(picker.startDate, "days");
    if (diffDays) {
      const startDayOfWeek = picker.startDate.format("dddd"); // Get the weekday name of the start date
      const endDayOfWeek = picker.endDate.format("dddd"); // Get the weekday name of the end date
      const startMonth = picker.startDate.format("MMMM"); // Get the month name of the start date
      const endMonth = picker.endDate.format("MMMM"); // Get the month name of the end date
      setWeekDayStartName(startDayOfWeek);
      setMonthStartName(startMonth);
      setWeekDayEndName(endDayOfWeek);
      setMonthEndName(endMonth);
    }
    setFromDate(moment(picker.startDate._d).format("YYYY-MM-DD"));
    setToDate(moment(picker.endDate._d).format("YYYY-MM-DD"));
    const payload = {
      formDate: moment(picker.startDate._d).format("YYYY-MM-DD"),
      toDate: moment(picker.endDate._d).format("YYYY-MM-DD")
    }
    dataHandler(payload);
  };

  return (
    <div className="date_range">
      <DateRangePicker
        initialSettings={{
          ranges: range,
        }}
        alwaysShowCalendars={true}
        onApply={handleEvent}
      >
        <button>
          <Stack flexDirection={"row"} gap={1} alignItems={"center"}>
            <div
              className="date_range_icon"
              style={{ background: "#00BFF520", borderRadius: "5px" }}
            >
              <FaRegCalendar />
            </div>
            <div style={{ lineHeight: "normal" }}>
              {!fromDate
                ? `Select Date Range`
                : `${moment(fromDate).format("L")} to ${moment(toDate).format(
                  "L"
                )}`}
            </div>
          </Stack>
        </button>
      </DateRangePicker>
    </div>
  );
}
