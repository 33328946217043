import {
  AccountMgtBlue,
  AlertBlue,
  DataMgtBlue,
  EducatorsMgtBlue,
  ReportingBlue,
  AccountMgtWhite,
  AlertWhite,
  DataMgtWhite,
  EducatorsMgtWhite,
  ReportingWhite,
} from "../helper/Constant";

export const SidebarList = [
  {
    name: "Setting",
    icon: "",
    activeIcon: "",
    path: "/settings",
  },
  {
    name: "Unknown Persons",
    icon: "",
    activeIcon: "",
    path: "/unknown-persons",
  },
  {
    name: "Modifications",
    icon: "",
    activeIcon: "",
    path: "/modifications",
  },
  {
    // name: "Daily Attendances",
    name: "Att. Daily",
    icon: "",
    activeIcon: "",
    path: "/attendances",
  },
  {
    name: "Att. Room",
    icon: "",
    activeIcon: "",
    path: "/attroom",
  },
  {
    name: "Att. SFI",
    icon: "",
    activeIcon: "",
    path: "/attsfi",
  },
  {
    name: "Att. Category",
    icon: "",
    activeIcon: "",
    path: "/attcat",
  },
  {
    name: "Att. None",
    icon: "",
    activeIcon: "",
    path: "/non-attendances",
  },
  {
    name: "Att. Exception",
    icon: "",
    activeIcon: "",
    path: "/exception-attend",
  },
  // {
  //   name: "Exp. Attend. Child",
  //   icon: "",
  //   activeIcon: "",
  //   path: "/exception-attend",
  // },
  // {
  //   name: "Exp. Attend. Edu.",
  //   icon: "",
  //   activeIcon: "",
  //   path: "/exception-attend-edu",
  // },
  {
    name: "Alarms",
    icon: "",
    activeIcon: "",
    path: "/alarms",
  },
  {
    name: "Close of day",
    icon: "",
    activeIcon: "",
    path: "/close-of-day",
  },
  // {
  //     name: 'Review Event.',
  //     icon: '',
  //     activeIcon: '',
  //     path: '/review-event'
  // },
  // {
  //     name: 'Settings',
  //     icon: '',
  //     activeIcon: '',
  //     path: '/settings'
  // },
];

export const genderList = [
  { name: "Male", value: "Male" },
  { name: "Female", value: "Female" },
  { name: "Other", value: "Other" },
];

export const subjectList = [
  { name: "Maths", value: "Maths" },
  { name: "English", value: "English" },
  { name: "Science", value: "Science" },
];

export const staffList = [
  { name: "Educator", value: "Educator" },
  { name: "Tradesperson", value: "Tradesperson" },
];

export const yesNoList = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" },
];

export const standardList = [
  { name: "1st", value: "1st" },
  { name: "2nd", value: "2nd" },
  { name: "3rd", value: "3rd" },
];

// export const classList = [
//     { name: '1A Class', value: '1A Class' },
//     { name: '2B Class', value: '2B Class' },
//     { name: '3C Class', value: '3C Class' },
// ]

export const relationshipList = [
  { value: "Mother", name: "Mother" },
  { value: "Father", name: "Father" },
];

export const blackWhiteList = [
  { value: "Blacklist", name: "Blacklist" },
  { value: "Whitelist", name: "Whitelist" },
];

export const categoryList = [
  { label: "Child", value: "child", name: "Child" },
  { label: "Guardian", value: "guardian", name: "Guardian" },
  { label: "Educator", value: "educator", name: "Educator" },
  { label: "Other", value: "treadperson", name: "Other" },
  { label: "Banned", value: "banned", name: "Banned" },
  // { label: "dailyRoaster", value: "", name: "DailyRoaster" },
];

export const classList = [
  { value: "None", name: "None" },
  { value: "Red Rm", name: "Red Rm" },
  { value: "Blur Rm", name: "Blur Rm" },
  { value: "Green", name: "Green" },
  { value: "Rm", name: "Rm" },
  { value: "Brown", name: "Brown" },
];

export const personList = [
  { label: "1001" },
  { label: "1002" },
  { label: "1003" },
  { label: "1004" },
  { label: "1005" },
];
