import React, { useEffect, useState } from "react";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { CloseIcon } from "../../helper/Icons";
import { useFormik } from "formik";
import { Button, Input } from "../commons";
import { classValidation } from "../../helper/Validation";
import { addClassIv } from "../../helper/intialValues";
import { getStandardAllList } from "../../Service/Standard.service";
import { createClassHandler, updateClassHandler } from "../../Service/class.service";
import { errorNotification, successNotification } from "../../helper/Notification";

const AddClass = ({ handleClose, closeFlag, modalOpen }) => {
  const response = modalOpen?.data?.totalData;
  const [errorDisplayFlag, setErrorDisplayFlag] = useState(false);
  const [loading, setLoading] = useState(false);
  const [standardId,setStandardId] = useState("");
  const [standardList, setStandardList] = useState([]);

  const standardChangeHandler = (event) => {
    setStandardId(event.target.value);
    formik.setFieldValue("standard", event.target.value);
  };

  const submitHandler = async () => {
    try {
      setLoading(true);
      const {data,message,success} = modalOpen.isEdit ?  await updateClassHandler (response.id,formik.values): await createClassHandler(formik.values);
      if(success){
        successNotification(message);
      }else{
        errorNotification(message);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(true);
      closeFlag();
      handleClose();
    }
  };

  useEffect(() => {
    if (modalOpen.isEdit) {
      getClassDetails(modalOpen?.data?.totalData);
    }
  }, []);

  const standardListHandler = async () => {
    const { data, message, success } = await getStandardAllList();
    if (success) {
      setStandardList(data);
    } else {
      setStandardList([]);
    }
  };

  const getClassDetails = (data) => {
    formik.setValues({
      ...formik.values,
      name:data.name,
      standard:data.standard
    });
    setStandardId(data.standard)
  };

  useEffect(() => {
    standardListHandler();
  }, []);

  const formik = useFormik({
    initialValues: addClassIv,
    validationSchema: classValidation,
    onSubmit: submitHandler,
  });

  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
        {modalOpen.isEdit ? 'Edit' : 'Add'} Class
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Grid container gap={2} maxWidth={{ sm: "400px" }} paddingY={1}>
          <Typography>{"Standard"}</Typography>
          <Select
            value={standardId}
            onChange={standardChangeHandler}
            displayEmpty
            fullWidth
          >
            {standardList &&
              standardList.length > 0 &&
              standardList.map((res, i) => {
                return (
                  <MenuItem value={res.id} key={i}>
                    {res.name}
                  </MenuItem>
                );
              })}
          </Select>
          {errorDisplayFlag && (
            <FormHelperText>{formik.errors.standard}</FormHelperText>
          )}
          <Grid item xs={12}>
            <Input
              labelinput="Class"
              formik={formik}
              name="name"
              inputProps={{ maxLength: 30 }}
              errorDisplay={errorDisplayFlag}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          buttonName="Cancel"
          size="small"
          color="white"
          onClick={handleClose}
        />
        <Button
          buttonName="Save"
          size="small"
          loading={loading}
          onClick={() => [formik.handleSubmit(), setErrorDisplayFlag(true)]}
        />
      </DialogActions>
    </>
  );
};

export default AddClass;
