import React, { useEffect, useState } from "react";
import { Box, Checkbox, Grid, Stack, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { Button, Input } from "../../components/commons";
import { useFormik } from "formik";
import { loginIv } from "../../helper/intialValues";
import { loginValidation } from "../../helper/Validation";
import { loginHandler } from "../../Service/user.service";
import { errorNotification, successNotification } from "../../helper/Notification";
import Cookies from "universal-cookie";

const Index = () => {
  const navigate = useNavigate();
  const [rememberMe, setRememberMe] = useState(false);
  const cookies = new Cookies();

  useEffect(()=>{
    const userToken = cookies.get('SFUSERTOKEN');
    if (userToken) {
      navigate(`/unknown-persons`);
    }
  },[]);

  useEffect(()=>{
    const userName = cookies.get("userName");
    const userPassword = cookies.get("userPassword");
    if(userName && userPassword){
      setRememberMe(true);
      formik.setValues({
        ...formik.values,
        username: userName,
        password: userPassword
      })
    }
  },[]);


  
  const handleRememberMeChange = (event) =>{
    setRememberMe(event.target.checked)
  };
  
  const submitHandler = async () =>{
    const { data, message, success } = await loginHandler(formik.values);
    if(success){
      successNotification(message);
      navigate(`/unknown-persons`);
      cookies.set("SFUSERTOKEN", data.access);
      cookies.set("PROFILE", data.profile_image);
      cookies.set("EMAIL", data.email);
      if (rememberMe) {
        cookies.set("userName", formik.values.username);
        cookies.set("userPassword", formik.values.password);
      } else {
        cookies.remove("userName");
        cookies.remove("userPassword");
      }
    }else{
      errorNotification(message);
    }
  };

  const formik = useFormik({
    initialValues: loginIv,
    validationSchema: loginValidation,
    onSubmit: submitHandler,
  });
  
  return (
    <Box
      sx={{
        position: "relative",
        width: { xs: "-webkit-fill-available", md: "100%" },
        height: "-webkit-fill-available",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Stack
        flexDirection={{ md: "row" }}
        alignItems={"center"}
        justifyContent={"center"}
        sx={{ width: "100%", height: "fit-content" }}
      >

        <Box
          width={{ xs: "94%", md: "45%" }}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Stack gap={{ xs: 4, sm: 7 }} width={{ xs: "100%", sm: "450px" }}>
            <Stack alignItems={'center'}>
              <Typography
                fontSize={{ xs: "35px", sm: "50px" }}
                className="Gilroy_ExtraBold"
              >
                Login
              </Typography>
              <Typography fontSize={{ xs: "17px", sm: "20px" }}>
                Welcome back to <Link to={"#"}>Safe Face</Link>
              </Typography>
            </Stack>
            <Stack gap={{ xs: 2, sm: 3 }}>
              <Input
                placeholder="UserName"
                name="username"
                formik={formik}
              />
              <Input
                placeholder="Password"
                passwordinput
                formik={formik}
                name="password"
              />
              <Box mt={2} width={"100%"}>
                <Button
                  buttonName="Sign In"
                  color="secondary"
                  onClick={() => formik.handleSubmit()}
                  style={{ width: "100%" }}
                />
              </Box>
              <Grid
                container
                sx={{ alignItems: "center", justifyContent: "space-between" }}
                marginTop={{ xs: 1, sm: 2 }}
              >
                <Grid item xs={12} alignItems={"center"}>
                  <Grid container alignItems={"center"}>
                    <Grid item>
                      <Checkbox
                        defaultChecked
                        checked={rememberMe}
                        onChange={handleRememberMeChange}
                        color="secondary"
                        sx={{
                          padding: "0",
                          marginRight: 1,
                          ml: 1,
                          "& .MuiSvgIcon-root": { fontSize: 26 },
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <Typography fontSize={"18px"}> Remember me</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
};

export default Index;
