import { TableCell, TableRow } from "@mui/material";
import React from "react";

function NoRecordFound({ colSpan }) {
  console.log('colSpan 0-0-0-0-0-0--0', colSpan);

  return (
    <TableRow>
      <TableCell
        sx={{
          color: "silver",
          textAlign: "center",
          paddingTop: "90px",
          borderBottom: "none",
          fontSize: "30px",
        }}
        colSpan={colSpan ? colSpan : 6}
      >
        No records to display
      </TableCell>
    </TableRow>
  );
}

export default NoRecordFound;
