import React, { useEffect, useState } from "react";
import { FaUserLarge } from "../../helper/Icons";
import { Box, Divider, Stack, Typography } from "@mui/material";
import Button from "./Button";
import Modal from "./Modal";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { useLocation } from "react-router-dom";

const UploadImage = ({acceptImage,imageHandler,Image,isView}) => {
  const location = useLocation();
  const [isFaceReg, setIsFaceReg] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
  });
  // State to store uploaded image
  const [image, setImage] = useState(null);
  const [previewUrl, setPreviewUrl] = useState("");

  const handleFaceRegClick = () => {
    setIsFaceReg(true);
  };
  const handleFaceRegClose = () => {
    setIsFaceReg(false);
  };

  useEffect(()=>{
    if(Image){
      setPreviewUrl(Image)
    }
  },[Image]);

  // Handle file input change and update the state
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImage(file);
      imageHandler(file);
      // Update the preview URL
      setPreviewUrl(URL.createObjectURL(file));
    } else {
      setImage(null);
      setPreviewUrl("");
    }
  };

  return (
    <>
      <Stack
        flexDirection={{ xs: "column", lg: "row" }}
        gap={{ xs: 2, sm: 4 }}
        justifyContent={"space-between"}
      >
        <Stack
          flexDirection={{ xs: "column", sm: "row" }}
          gap={{ xs: 2, sm: 4 }}
          alignItems={{ sm: "center" }}
        >
          <Box
            sx={{
              backgroundColor: "#ede9e6",
              borderRadius: "15px",
              width: { xs: "90px", sm: "110px" },
              height: { xs: "90px", sm: "110px" },
              padding: "8px",
            }}
          >
            {/* {/ Display the selected image or a default icon /} */}
            {previewUrl ? (
              <img
                src={previewUrl}
                alt="Preview"
                style={{ width: "100%", height: "100%", borderRadius: "15px" }}
              />
            ) : (
              <FaUserLarge
                className="image_upload_icon"
                style={{ borderRadius: "0", color: "#00BFF5" }}
              />
            )}
          </Box>
          <Stack gap={2}>
            <Stack
              flexDirection={{ xs: "row", sm: "row" }}
              gap={{ xs: 1, sm: 2 }}
            >
              {/* {/ Image input field /} */}
              <label htmlFor="upload-image" style={{position:'relative',overflow:'hidden',}}>
              <input
                type="file"
                disabled={isView}
                accept={acceptImage}
                onChange={handleImageChange}
                style={{ display: "block",position:'absolute',height:'100%',opacity:'0',zIndex:'1',cursor:'pointer' }}
                id="upload-image"
              />
                {
                  !isView && (
                    <Button
                      component="span"
                      disabled={isView}
                      buttonName="Upload Profile Photo"
                      size="small"
                    />
                  )
                }
              </label>
              {
                !isView && (
                  <Button
                    buttonName="Reset"
                    color="white"
                    size="small"
                    disabled={isView}
                    onClick={() => [setPreviewUrl(""),imageHandler(null)]}
                  />
                ) 
              }
            </Stack>
            {
              !isView && (
              <Typography fontSize={"18px"} sx={{ color: "#aaa" }}>
                Allowed Jpg,png
              </Typography>
              )
            }
          </Stack>
        </Stack>
      </Stack>
      <Modal
        modalOpen={isModalOpen}
        handleClose={() =>
          setIsModalOpen({
            open: false,
            currentComponent: "",
            para: "",
            head: "",
          })
        }
      />
    </>
  );
};

export default UploadImage;
