import { DELETE, get, patch, post, put } from "../Web.request";

export const tradesPersonListHandler = (search, page, startDate, endDate) => {
  return get(
    `${process.env.REACT_APP_API_URL}/users/treadperson_data?search=${search}&start_date=${startDate}&end_date=${endDate}&page=${page}`
  );
};

export const deleteTradesPersonById = (id) => {
  return DELETE(
    `${process.env.REACT_APP_API_URL}/users/delete_treadperson/${id}/`
  );
};

export const createTradesPersonHandler = (payload) => {
  return post(
    `${process.env.REACT_APP_API_URL}/users/create_treadperson`,
    payload
  );
};

export const updateTradesPersonHandler = (id, payload) => {
  return patch(
    `${process.env.REACT_APP_API_URL}/users/treadperson_update/${id}/`,
    payload
  );
};
