import React, { useState } from "react";
import { MenuItem, Select, Typography } from "@mui/material";

export default function CSelect(props) {
  const {
    selectHead,
    selectList,
    labelSelect,
    style,
    className,
    onChange,
    disabled,
    value,
  } = props;
  return (
    <>
      {labelSelect && (
        <Typography
          fontSize={{ xs: "15px", sm: "18px" }}
          fontWeight={500}
          mb={"2px"}
        >
          {labelSelect}
        </Typography>
      )}
      <Select
        value={value}
        onChange={onChange}
        displayEmpty
        fullWidth
        sx={style}
        className={className}
        disabled={disabled}
      >
        {selectHead && (
          <MenuItem value="" hidden disabled>
            {selectHead}
          </MenuItem>
        )}
        {selectList.map((data, i) => (
          <MenuItem value={data.value} key={i}>
            {data.name}
          </MenuItem>
        ))}
      </Select>
    </>
  );
}
