import { Skeleton, TableCell, TableRow } from '@mui/material'
import React from 'react'

const TableSkeleton = ({ rowsNum, colsNum }) => {
    return (
        <>
            {[...Array(rowsNum)].map((row, i) => (
                <TableRow key={i}>
                    {[...Array(colsNum)].map((row, index) => (
                        <TableCell key={index} component="th" scope="row">
                            <Skeleton animation="wave" variant="text" width={'100%'} height={25} />
                        </TableCell>
                    ))}
                </TableRow>
            ))}
        </>
    )
}

export default TableSkeleton