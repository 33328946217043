import React from "react";
import TextField from "@mui/material/TextField";
import { Box, IconButton, Typography } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { Visibility, VisibilityOff } from "../../helper/Icons";

const CommonInput = (props) => {
  const {
    type,
    placeholder,
    iconinput,
    labelinput,
    value,
    disabled,
    passwordinput,
    style,
    errorDisplay,
    className,
  } = props;
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  return (
    <>
      {labelinput && (
        <Typography
          fontSize={{ xs: "15px", sm: "18px" }}
          fontWeight={500}
          mb={"2px"}
        >
          {labelinput}
        </Typography>
      )}
      <Box position={"relative"}>
        <TextField
          fullWidth
          hiddenLabel
          disabled={disabled}
          placeholder={placeholder}
          value={value}
          type={
            type
              ? type
              : passwordinput
              ? showPassword
                ? "text"
                : "password"
              : "text"
          }
          color="primary"
          sx={style}
          className={className}
          error={props?.formik?.touched[props.name] && props?.formik?.errors[props.name]}
          {...props?.formik?.getFieldProps(props.name)}
          helperText={props?.formik?.touched[props.name] && props?.formik?.errors[props.name]}
          {...props}
        />
        {(passwordinput || iconinput) && passwordinput ? (
          <InputAdornment
            position="end"
            sx={{
              height: "100%",
              position: "absolute",
              right: { xs: "7px", sm: "9px" },
              top: { xs: "7px", sm: "9px" },
            }}
          >
            <IconButton onClick={handleClickShowPassword}>
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ) : (
          iconinput && (
            <InputAdornment
              position="end"
              sx={{
                height: "100%",
                position: "absolute",
                right: { xs: "7px", sm: "9px" },
                top: { xs: "7px", sm: "9px" },
              }}
            >
              <IconButton> {iconinput} </IconButton>
            </InputAdornment>
          )
        )}
      </Box>
    </>
  );
};

export default CommonInput;
