import { ThemeProvider } from "@mui/material";
import { Route, Routes } from "react-router-dom";
import { theme } from "./helper/Theme";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ScrollToTop from "./helper/ScrollToTop";
import Layout from "./components/layouts/Layout";
import Login from "./pages/Login";
import "./App.css";
import PrivateRoute from "./helper/PrivateRoutes";
import UnknownPersons from "./pages/UnknownPersons";
import AddPerson from "./pages/AddPerson";
import Modifications from "./pages/Modifications";
import Alarms from "./pages/Alarms";
import Attendances from "./pages/Attendances";
import AttRomms from "./pages/Attendances/attrooms";
import AttSFI from "./pages/Attendances/attSFI";
import AttCategory from "./pages/Attendances/attcategory";
import NonAttendance from "./pages/NonAttendance";
import ExtendedAttend from "./pages/ExtendedAttend";
import ExpAttEdu from "./pages/ExtendedAttend/Expattedu";
import CloseOfDay from "./pages/CloseOfDay";
import Settings from "./pages/Settings";

const pages = [
  { name: "UnknownPersons", path: "unknown-persons", element: <UnknownPersons /> },
  { name: "AddPerson", path: "add-person", element: <AddPerson /> },
  { name: "Modifications", path: "modifications", element: <Modifications /> },
  { name: "Alarms", path: "alarms", element: <Alarms /> },
  { name: "Attendances", path: "attendances", element: <Attendances /> },
  { name: "Attendances Room", path: "attroom", element: <AttRomms /> },
  { name: "Attendances SFI", path: "attsfi", element: <AttSFI /> },
  { name: "Attendances SFI", path: "attcat", element: <AttCategory /> },
  { name: "NonAttendance", path: "non-attendances", element: <NonAttendance /> },
  { name: "ExtendedAttend", path: "exception-attend", element: <ExtendedAttend /> },
  { name: "ExtendedAttendEdu", path: "exception-attend-edu", element: <ExpAttEdu /> },
  { name: "CloseOfDay", path: "close-of-day", element: <CloseOfDay /> },
  { name: "Settings", path: "settings", element: <Settings /> },
];

function App() {
  return (
    <ScrollToTop>
      <ThemeProvider theme={theme}>
        <div className="background_Image">
          <Routes>
            <Route path="/" element={<Login />} />
            <Route element={<PrivateRoute />}>
              {pages.map((page, index) => {
                const { name, path, element } = page;
                return (
                  <Route
                    key={index}
                    exact
                    path={`/${path}`}
                    name={name}
                    element={<Layout>{element}</Layout>}
                  />
                );
              })}
            </Route>
          </Routes>
        </div>
        <ToastContainer />
      </ThemeProvider>
    </ScrollToTop>
  );
}

export default App;
