import React, { useEffect, useState } from "react";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";
import { CloseIcon } from "../../helper/Icons";
import { Button, Input } from "../commons";
import { useFormik } from "formik";
import { addSubjectIv } from "../../helper/intialValues";
import { subjectValidation } from "../../helper/Validation";
import {
  addSubjectHandler,
  editSubjectHandler,
} from "../../Service/Subject.service";
import {
  errorNotification,
  successNotification,
} from "../../helper/Notification";
function AddSubject({ handleClose, closeFlag, modalOpen }) {
  const [errorDisplayFlag, setErrorDisplayFlag] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (modalOpen?.isEdit) {
      getStudentDetails(modalOpen?.data?.totalData);
    }
  }, []);

  const getStudentDetails = (data) => {
    formik.setValues({
      ...formik.values,
      subject_name: data.subject_name,
    });
  };

  const submitHandler = async () => {
    try {
      setLoading(true);
      const { data, message, success } = modalOpen?.isEdit
        ? await editSubjectHandler(
            modalOpen?.data?.totalData?.id,
            formik.values
          )
        : await addSubjectHandler(formik.values);
      if (success) {
        successNotification(message);
      } else {
        errorNotification(message);
      }
    } catch (err) {
      console.error(err);
    } finally {
      handleClose();
      closeFlag();
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: addSubjectIv,
    validationSchema: subjectValidation,
    onSubmit: submitHandler,
  });

  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
        {modalOpen?.isEdit ? " Edit" : "Add"} Subject
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Grid container gap={2} width={{ sm: "400px" }} paddingY={1}>
          <Grid item xs={12}>
            <Input
              labelinput="Subject"
              errorDisplay={errorDisplayFlag}
              name="subject_name"
              inputProps={{ maxLength: 30 }}
              formik={formik}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          buttonName="Cancel"
          size="small"
          color="white"
          onClick={handleClose}
        />
        <Button
          buttonName="Save"
          size="small"
          loading={loading}
          onClick={() => [formik.handleSubmit(), setErrorDisplayFlag(true)]}
        />
      </DialogActions>
    </>
  );
}

export default AddSubject;
