import { useStyles } from "../../helper/Theme";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Stack,
  Typography,
  Grid,
  Switch,
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
} from "@mui/material";
import { Unknown } from "../../helper/Constant";
import { AutoComplete, Button, Input, Select } from "../../components/commons";
import { categoryList, classList, personList } from "../../helper/Helper";
import { useState, useEffect } from "react";
import { IoIosAdd } from "react-icons/io";
import { IoClose } from "react-icons/io5";
import EducatorPerson from "../CategoreyWise/EducatorPerson";
import GuardianPerson from "../CategoreyWise/GuardianPerson";
import ChildPerson from "../CategoreyWise/ChildPerson";
import {
  modificationToPerson,
  updateModificationApihandler,
} from "../../Service/modificationPerson";
import {
  errorNotification,
  successNotification,
} from "../../helper/Notification";
import BannedPerson from "../CategoreyWise/BannedPerson";

export default function Index() {
  const classes = useStyles();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const navigationData = location.state;
  const navigate = useNavigate();

  const [categoryValue, setCategoryValue] = useState("");
  const [optOutFlag, setOptOutFlag] = useState(false);

  const handleCategoryChange = (event) => {
    setCategoryValue(event.target.value);
  };

  useEffect(() => {
    if (navigationData?.editFlage) {
      setCategoryValue(navigationData.categorey);
    }
  }, []);

  const modificationHandler = async () => {
    try {
      setLoading(true);
      const payload = {
        opt_out: optOutFlag,
        category: categoryValue,
      };
      const { data, message, success } = navigationData?.editFlage
        ? await updateModificationApihandler(navigationData.personId, payload)
        : await modificationToPerson(navigationData?.personId, payload);
      if (success) {
        navigate("/modifications");
        successNotification(message);
      } else {
        errorNotification(message);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (navigationData) {
      setOptOutFlag(navigationData?.opt_out);
    }
  }, []);

  const optoutHandler = (value) => {
    setOptOutFlag(value);
  };

  return (
    <Stack gap={4}>
      {(categoryValue === "treadperson" || categoryValue === "") && (
        <Stack flexDirection={"row"} gap={7} alignItems={"start"}>
          <Box>
            <Stack justifyContent={"center"} alignItems={"center"} gap={2}>
              <Box sx={{ display: "flex" }}>
                {/* <IoClose className="closeicon" /> */}
                <img
                  src={
                    navigationData?.personImage
                      ? navigationData?.personImage
                      : ""
                  }
                  alt=""
                  width={550}
                  height={600}
                />
              </Box>
              <Typography variant="h3" className="Gilroy_Bold">
                {navigationData?.personName ? navigationData?.personName : "-"}
              </Typography>
            </Stack>
          </Box>
          <Stack sx={{ width: "100%", mt: 5 }}>
            <Box sx={{ width: "100%" }}>
              <Stack
                flexDirection={"row"}
                justifyContent={"space-between"}
                gap={3}
              >
                <Box>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Stack
                        flexDirection={"row"}
                        alignItems={"center"}
                        justifyContent={"start"}
                      >
                        <Typography
                          fontSize={{ xs: "18px", sm: "38px" }}
                          fontWeight={"600"}
                        >
                          Opt Out :
                        </Typography>
                        &nbsp; &nbsp; &nbsp;
                        <Switch
                          checked={optOutFlag}
                          onChange={(event) =>
                            optoutHandler(event.target.checked)
                          }
                          color="secondary"
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12}>
                      <Stack
                        flexDirection={"row"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                        gap={2}
                        sx={{ width: "500px" }}
                      >
                        <Typography
                          fontSize={{ xs: "18px", sm: "38px" }}
                          fontWeight={"600"}
                          sx={{ whiteSpace: "nowrap" }}
                        >
                          Category :
                        </Typography>
                        <Select
                          style={{ maxWidth: "300px" }}
                          selectHead="Select Category"
                          selectList={categoryList}
                          value={categoryValue}
                          onChange={handleCategoryChange}
                        />
                      </Stack>
                    </Grid>
                  </Grid>
                </Box>
              </Stack>
            </Box>
          </Stack>
        </Stack>
      )}
      <Grid container>
        {console.log("categoryValue", categoryValue)}
        {categoryValue === "child" && (
          <Grid item xs={12}>
            <ChildPerson
              optOutFlag={optOutFlag}
              navigationData={navigationData}
              optoutHandler={optoutHandler}
              categoryList={categoryList}
              categoryValue={categoryValue}
              handleCategoryChange={handleCategoryChange}
            />
          </Grid>
        )}
        {categoryValue === "educator" && (
          <Grid item xs={12}>
            <EducatorPerson
              optOutFlag={optOutFlag}
              navigationData={navigationData}
              optoutHandler={optoutHandler}
              categoryList={categoryList}
              categoryValue={categoryValue}
              handleCategoryChange={handleCategoryChange}
            />
          </Grid>
        )}

        {categoryValue === "guardian" && (
          <Grid item xs={12}>
            <GuardianPerson
              optOutFlag={optOutFlag}
              navigationData={navigationData}
              optoutHandler={optoutHandler}
              categoryList={categoryList}
              categoryValue={categoryValue}
              handleCategoryChange={handleCategoryChange}
            />
          </Grid>
        )}
        {categoryValue === "banned" && (
          <Grid item xs={12}>
            <BannedPerson
              optOutFlag={false}
              navigationData={navigationData}
              optoutHandler={optoutHandler}
              categoryList={categoryList}
              categoryValue={categoryValue}
              handleCategoryChange={handleCategoryChange}
            />
          </Grid>
        )}
      </Grid>

      {categoryValue === "treadperson" && (
        <Stack
          flexDirection={"row"}
          gap={{ xs: 2 }}
          mt={2}
          justifyContent={"center"}
        >
          <Button
            buttonName={"Save"}
            loading={loading}
            disabled={loading}
            onClick={() => modificationHandler()}
          />
          <Button
            buttonName="Exit"
            color="white"
            onClick={() => navigate("/modifications")}
          />
        </Stack>
      )}
    </Stack>
  );
}
