export const loginIv = {
  username: "",
  password: "",
};

export const standardIv = {
  name: "",
};

export const addClassIv = {
  name: "",
  standard: "",
};

export const addStudentIv = {
  username: "",
  first_name: "",
  last_name: "",
  contact_number: "",
  email: "",
  gender: "",
  dob: "",
  // need to keep this commented
  // is_blacklisted:"",
  // opt_out:"",
  standerd_with_division: "",
  standard_id: "",
  profile_image: "",
};

export const AddGuardianIv = {
  username: "",
  first_name: "",
  last_name: "",
  contact_number: "",
  email: "",
  profile_image: null,
  gender: "",
  dob: "",
  is_blacklisted: "",
  opt_out: "",
};

export const addSubjectIv = {
  subject_name: "",
};

export const addStaffIv = {
  username: "",
  first_name: "",
  last_name: "",
  contact_number: "",
  email: "",
  profile_image: "",
  gender: "",
  dob: "",
  is_blacklisted: false,
  opt_out: true,
  educator_subject: "",
};

export const addTreadPersonIv = {
  first_name: "",
  last_name: "",
  username: "",
  contact_number: "",
  email: "",
  profile_image: "",
  gender: "",
  dob: "",
  is_blacklisted: false,
  opt_out: true,
};

export const addLocationIv = {
  location: "",
};

export const addDeviceIv = {
  location: "",
  device_name: "",
  ip_address: "",
  rtsp_url: "",
  fps: "",
  device_port: "",
  device_username: "",
  device_password: "",
};

// new intial Values

export const educatorPersonIv = {
  category: "educator",
  standard_id: "",
  opt_out: "",
  // rosted_hours: "",
  enrollment_data: [
    {
      day: "Monday",
      entry_time: "",
      exit_time: "",
    },
    {
      day: "Tuesday",
      entry_time: "",
      exit_time: "",
    },
    {
      day: "Wednesday",
      entry_time: "",
      exit_time: "",
    },
    {
      day: "Thursday",
      entry_time: "",
      exit_time: "",
    },
    {
      day: "Friday",
      entry_time: "",
      exit_time: "",
    },
    {
      day: "Saturday",
      entry_time: "",
      exit_time: "",
    },
    {
      day: "Sunday",
      entry_time: "",
      exit_time: "",
    },
  ],
};

export const guardianPersonIv = {
  category: "guardian",
  student_ids: [],
  master_student_ids: [],
  opt_out: "",
};

export const childPersonIv = {
  category: "child",
  standard_id: "",
  guardians_ids: [],
  master_guardians_ids: [],
  opt_out: "",
  enrollment_data: [
    {
      day: "Monday",
      entry_time: "",
      exit_time: "",
    },
    {
      day: "Tuesday",
      entry_time: "",
      exit_time: "",
    },
    {
      day: "Wednesday",
      entry_time: "",
      exit_time: "",
    },
    {
      day: "Thursday",
      entry_time: "",
      exit_time: "",
    },
    {
      day: "Friday",
      entry_time: "",
      exit_time: "",
    },
    {
      day: "Saturday",
      entry_time: "",
      exit_time: "",
    },
    {
      day: "Sunday",
      entry_time: "",
      exit_time: "",
    },
  ],
};

export const bannedPerson = {
  category: "banned",
  student_ids: [],
  master_guardians_ids: [],
  opt_out: false,
};
