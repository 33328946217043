import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { CloseIcon } from "../../../helper/Icons";
import { Button } from "../../commons";
import {
  getGurdianDetailById,
  mapGurdianHandler,
} from "../../../Service/guardian.service";
import {
  errorNotification,
  successNotification,
} from "../../../helper/Notification";

const Confirmation = ({ handleClose, modalOpen, closeFlag }) => {
  const navigate = useNavigate();
  const [guardianDetails, setGurdianDetails] = useState([]);
  const [guardianId, setGuardianId] = useState([]);
  const [selectGurdianName, setSelectGurdianName] = useState("");
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState(false);
  const handleClick = () => {
    setSearch(true);
  };
  const agreeHandler = async () => {
    const payload = {
      student_map: modalOpen?.data?.studentId,
      guardian_map: guardianId,
      master_Guardian: false,
    };
    const { data, message, success } = await mapGurdianHandler(payload);
    try {
      if (success) {
        setLoading(true);
        successNotification(message);
        closeFlag();
      } else {
        errorNotification(message);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
      handleClose();
    }
  };

  useEffect(() => {
    guardianDetailsHandler();
  }, []);

  const guardianDetailsHandler = async () => {
    const { count, data, message, success, totalPage } =
      await getGurdianDetailById("");
    const guardianData =
      data &&
      data.map((res) => {
        return {
          label: res.username,
          id: res.id,
        };
      });
    setGurdianDetails(guardianData);
  };

  return (
    <div>
      <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
        {search ? "Search" : "Add"} Guardian
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Box width={{ sm: "400px" }}>
          {search ? (
            <Autocomplete
              freeSolo
              id="multiple-limit-tags"
              options={guardianDetails}
              value={selectGurdianName}
              isOptionEqualToValue={(option, value) =>
                option.label === value.label
              }
              renderInput={(params) => (
                <TextField {...params} label="" placeholder="" />
              )}
              sx={{ width: { xs: "220px", sm: "100%" } }}
              onChange={(event, value) => {
                setGuardianId(value.id);
              }}
            />
          ) : (
            <Typography fontSize={"18px"}>
              Do you want add Guardians from the list?{" "}
            </Typography>
          )}
        </Box>
      </DialogContent>
      {search ? (
        <DialogActions>
          <Button
            buttonName="cancel"
            size="small"
            color="white"
            onClick={handleClose}
          />
          <Button
            buttonName="Save"
            size="small"
            onClick={() => agreeHandler()}
          />
        </DialogActions>
      ) : (
        <DialogActions>
          <Button
            buttonName="No"
            size="small"
            color="white"
            onClick={() =>
              navigate("/addguardian", {
                state: {
                  studentId: modalOpen?.data?.studentId,
                },
              })
            }
          />
          <Button buttonName="Yes" size="small" onClick={handleClick} />
        </DialogActions>
      )}
    </div>
  );
};

export default Confirmation;
