import Pagination from "../../components/commons/Table/Pagination";
import { useStyles } from "../../helper/Theme";
import { useNavigate } from "react-router-dom";
import {
  Paper,
  Table,
  TableCell,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Stack,
  Autocomplete,
  TextField,
  Typography,
} from "@mui/material";
import { Unknown } from "../../helper/Constant";
import { AutoComplete, DateRange } from "../../components/commons";
import { categoryList } from "../../helper/Helper";
import { useState, useEffect } from "react";
import { getModificationListApiHandler } from "../../Service/modificationPerson";
import { TableSkeleton } from "../../components/commons/Table";
import NoRecordFound from "../../components/commons/NoRecordFound";
import { newPersonListHandler } from "../../Service/personList.service";
import SubHeader from "../../components/commons/SubHeader";
export default function Index() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [searchText, setSearchText] = useState("");
  const [personType, setPersonType] = useState("");
  const [loading, setLoading] = useState(false);
  const [dataCount, setDataCount] = useState(0);
  const [page, setPage] = useState(1);
  const [modificationData, setModificationData] = useState([]);
  const [personList, setPersonList] = useState([]);
  const [selectPerson, setSelectPerson] = useState("");

  useEffect(() => {
    getAllModificationList(page, startDate, endDate, searchText, personType);
    getPersonListHandler();
  }, [personType, selectPerson]);

  const getPersonListHandler = async () => {
    try {
      const { count, data, message, success, totalPage } =
        await newPersonListHandler();
      if (success) {
        const personData =
          data &&
          data.map((res) => {
            return {
              label: res.username,
              id: res.id,
            };
          });
        setPersonList(personData);
      } else {
        setPersonList([]);
      }
    } catch (err) {
      console.error("error", err);
    }
  };

  const getAllModificationList = async (
    page,
    startDate,
    endDate,
    search,
    personType
  ) => {
    try {
      setLoading(true);
      const { count, data, message, success, totalPage } =
        await getModificationListApiHandler(
          page,
          startDate,
          endDate,
          search,
          personType
        );
      if (success) {
        setDataCount(count);
        setModificationData(data);
      } else {
        setDataCount(0);
        setModificationData([]);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const pageChangeHandler = (data) => {
    setPage(data);
    getAllModificationList(data, startDate, endDate, searchText, personType);
  };

  return (
    <>
      <Stack
        flexDirection={"row"}
        gap={2}
        mb={2}
      >
        <Typography className="white-box" style={{ justifyContent: "center", fontWeight: "600", fontSize: "22px" }}>
          SFI:-
        </Typography>
        <Autocomplete
          freeSolo
          value={selectPerson}
          sx={{ width: 300, backgroundColor: "#fff", borderRadius: "10px" }}
          options={personList}
          onChange={(event, value) => {
            if (value) {
              setSelectPerson(value);
              setPersonType(value?.id ? value?.id : "");
            } else {
              setSelectPerson("");
              setPersonType(value?.id);
            }
          }}

          renderInput={(params) => (
            <TextField {...params} placeholder={"eg SFI"} />
          )}
        />
        {/* <SubHeader Module={"MODIFICATIONS"} buttonName={"export as Pdf"}/> */}
      </Stack>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow className="MuiReportTable-root">
              <TableCell>SFI</TableCell>
              <TableCell>Image</TableCell>
              <TableCell>Opt Out</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>Room</TableCell>
              <TableCell>Master</TableCell>
              <TableCell sx={{ textAlign: "center !important" }}>
                Linked
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableSkeleton rowsNum={10} colsNum={7} />
            ) : modificationData && modificationData.length > 0 ? (
              modificationData.map((response, i) => {
                console.log("response---->", response);
                const {
                  CATEGORY,
                  IMAGE,
                  id,
                  LINKED,
                  MASTER,
                  ROOM,
                  SFI,
                  OPT_OUT,
                } = response;
                return (
                  <TableRow
                    key={i}
                    className="MuiReportTable-root"
                    onClick={() =>
                      navigate("/add-person", {
                        state: {
                          personId: id,
                          personName: SFI,
                          categorey: CATEGORY,
                          editFlage: true,
                          responseData: { response },
                          personImage: IMAGE ? IMAGE : "",
                          opt_out: OPT_OUT,
                        },
                      })
                    }
                    // onClick={() => navigate("/add-person")}
                    sx={{ cursor: "pointer" }}
                  >
                    <TableCell>{SFI ? SFI : "-"}</TableCell>
                    <TableCell>
                      <img
                        src={IMAGE ? IMAGE : ""}
                        alt=""
                        width={100}
                        height={100}
                      />
                    </TableCell>
                    <TableCell>{OPT_OUT ? "Yes" : "No"}</TableCell>
                    <TableCell>{CATEGORY ? CATEGORY : "-"}</TableCell>
                    <TableCell>{ROOM.name ? ROOM.name : "-"}</TableCell>
                    <TableCell>
                      <Stack
                        flexDirection={"row"}
                        gap={1}
                        justifyContent={"center"}
                      >
                        {MASTER && MASTER.length > 0 ? (
                          MASTER.map((response, i) => {
                            return (
                              <img
                                key={i}
                                src={
                                  response.profile_image
                                    ? response.profile_image
                                    : ""
                                }
                                alt=""
                                width={100}
                                height={100}
                              />
                            );
                          })
                        ) : (
                          <> </>
                        )}
                      </Stack>
                    </TableCell>
                    <TableCell>
                      <Stack
                        flexDirection={"row"}
                        gap={1}
                        justifyContent={"center"}
                      >
                        {LINKED && LINKED.length > 0 ? (
                          LINKED.map((res, i) => {
                            return (
                              <img
                                key={i}
                                src={
                                  res.profile_image
                                    ? res.profile_image
                                    : ""
                                }
                                alt=""
                                width={100}
                                height={100}
                              />
                            );
                          })
                        ) : (
                          <></>
                        )}
                      </Stack>
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <NoRecordFound colSpan={7} />
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        pageNumber={page}
        count={dataCount}
        pageChangeHandler={pageChangeHandler}
        perPage={5}
      />
    </>
  );
}
