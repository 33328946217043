import {
  Autocomplete,
  Box,
  Grid,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { getStandardAllList } from "../../Service/Standard.service";
import { personList } from "../../helper/Helper";
import { Button } from "../../components/commons";
import { IoIosAdd } from "react-icons/io";
import { IoClose } from "react-icons/io5";
import { Unknown } from "../../helper/Constant";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { guardianPersonIv } from "../../helper/intialValues";
import { guardianPersonValidation } from "../../helper/Validation";
import { getAllStudentDataApiHandler } from "../../Service/Student.service";
import {
  modificationToPerson,
  updateModificationApihandler,
} from "../../Service/modificationPerson";
import {
  errorNotification,
  successNotification,
} from "../../helper/Notification";
import CSelect from "../../components/commons/CSelect";
import { Modal } from "../../components/commons";

function GuardianPerson({
  optOutFlag,
  clearFlag,
  optoutHandler,
  categoryList,
  categoryValue,
  handleCategoryChange,
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const navigationData = location.state;
  const [loading, setLoading] = useState(false);

  const [masterList, setMasterList] = useState([]);
  const [master, setMaster] = useState("");

  const [LinkedList, setLinkedList] = useState([]);
  const [Link, setLink] = useState("");

  const [selectMasterData, setSelectMasterData] = useState([]);
  const [selectLinkData, setSelectLinkData] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
    para: "",
    head: "",
  });

  const closeFlagHandler = () => {
    navigate(`/modifications`);
  };

  const getMasterDataHandler = async () => {
    const { count, data, message, success, totalPage } =
      await getAllStudentDataApiHandler();
    if (success) {
      const masterData =
        data &&
        data.map((res) => {
          return {
            label: res.username ? res.username : "",
            id: res.id ? res.id : "",
            profilePicture: res.profile_image ? res.profile_image : "",
          };
        });
      setMasterList(masterData);
    } else {
      setMasterList([]);
    }
  };

  useEffect(() => {
    if (navigationData?.editFlage) {
      getDataHandler(navigationData?.responseData);
    }
  }, []);

  const getDataHandler = (data) => {
    if (data?.response?.LINKED.length > 0) {
      const linkedPayload = data?.response?.LINKED.map((res) => {
        return {
          label: res.user_name ? res.user_name : "",
          id: res.id ? res.id : "",
          profilePicture: res.profile_image ? res.profile_image : "",
        };
      });
      setSelectLinkData(linkedPayload);
      const linkedData = data?.response?.LINKED.map((response) => response.id);
      formik.setFieldValue("student_ids", linkedData);
    }
    if (data?.response?.MASTER.length > 0) {
      const masterPayload =
        data?.response?.MASTER &&
        data?.response?.MASTER.map((res) => {
          return {
            label: res.user_name ? res.user_name : "",
            id: res.id ? res.id : "",
            profilePicture: res.profile_image ? res.profile_image : "",
          };
        });
      // setMaster(masterPayload[0]?.id);
      // console.log
      // setMaster(masterPayload[0]);
      formik.setFieldValue("master_student_ids", [masterPayload[0]?.id]);
      setSelectMasterData(masterPayload);
    }
  };

  const getLinkedDataHandler = async () => {
    const { count, data, message, success, totalPage } =
      await getAllStudentDataApiHandler();
    if (success) {
      const linkedData =
        data &&
        data.map((res) => {
          return {
            label: res.username ? res.username : "",
            id: res.id,
            profilePicture: res.profile_image,
          };
        });
      setLinkedList(linkedData);
    } else {
      setLinkedList([]);
    }
  };

  useEffect(() => {
    getLinkedDataHandler();
    getMasterDataHandler();
  }, []);

  useEffect(() => {
    formik.setFieldValue("opt_out", optOutFlag);
  }, [optOutFlag]);

  const addGurdianHandler = async () => {
    try {
      setLoading(true);
      const { data, message, success } = navigationData?.editFlage
        ? await updateModificationApihandler(
          navigationData.personId,
          formik.values
        )
        : await modificationToPerson(navigationData.personId, formik.values);
      if (success) {
        successNotification(message);
        navigate("/modifications");
      } else {
        errorNotification(message);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: guardianPersonIv,
    // validationSchema: guardianPersonValidation,
    onSubmit: addGurdianHandler,
  });

  const deleteMasterData = (id) => {
    const updatedMasterData = selectMasterData.filter((item) => item.id !== id);
    console.log("deleteMasterData", updatedMasterData);
    setSelectMasterData(updatedMasterData);
    setMaster("");
    const idData = updatedMasterData.map((item) => item.id);
    formik.setFieldValue("student_ids", idData);
  };

  const deleteLinkData = (id) => {
    const updatedLinkData = selectLinkData.filter((item) => item.id !== id);
    console.log("updatedLinkData", updatedLinkData);
    setSelectLinkData(updatedLinkData);
    const idData = updatedLinkData.map((res) => res.id);
    formik.setFieldValue("master_student_ids", idData);
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Stack flexDirection={"row"} gap={7}>
            <Box>
              <Stack justifyContent={"center"} alignItems={"center"} gap={2}>
                <Box sx={{ display: "flex" }}>
                  <IoClose className="closeicon"
                    onClick={() =>
                      setIsModalOpen({
                        open: true,
                        currentComponent: "deleteChild",
                        para: "Are you sure want to delete ?",
                        head: "Delete Guardian",
                        id: navigationData.personId,
                        name: "GUARDIAN",
                      })} />
                  <img
                    src={
                      navigationData?.personImage
                        ? navigationData?.personImage
                        : ""
                    }
                    alt=""
                    width={550}
                    height={600}
                  />

                </Box>
                <Typography variant="h2" className="Gilroy_Bold">
                  {navigationData?.personName ? navigationData?.personName : "-"}
                </Typography>
              </Stack>
            </Box>
            <Stack gap={4}>
              <Stack gap={3} mt={3}>
                <Stack sx={{ width: "100%", mt: 5 }}>
                  <Box sx={{ width: "100%" }}>
                    <Stack
                      flexDirection={"row"}
                      justifyContent={"space-between"}
                      gap={3}
                    >
                      <Box>
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <Stack
                              flexDirection={"row"}
                              alignItems={"center"}
                              justifyContent={"start"}
                            >
                              <Typography
                                fontSize={{ xs: "18px", sm: "38px" }}
                                fontWeight={"600"}
                              >
                                Opt Out :
                              </Typography>
                              &nbsp; &nbsp; &nbsp;
                              <Switch
                                checked={optOutFlag}
                                onChange={(event) =>
                                  optoutHandler(event.target.checked)
                                }
                                color="secondary"
                              />
                            </Stack>
                          </Grid>
                          <Grid item xs={12}>
                            <Stack
                              flexDirection={"row"}
                              alignItems={"center"}
                              justifyContent={"space-between"}
                              gap={2}
                              sx={{ width: "500px" }}
                            >
                              <Typography
                                fontSize={{ xs: "18px", sm: "38px" }}
                                fontWeight={"600"}
                                sx={{ whiteSpace: "nowrap" }}
                              >
                                Category :
                              </Typography>
                              <CSelect
                                style={{ maxWidth: "300px" }}
                                selectHead="Select Category"
                                selectList={categoryList}
                                value={categoryValue}
                                onChange={handleCategoryChange}
                              />
                            </Stack>
                          </Grid>
                        </Grid>
                      </Box>
                    </Stack>
                  </Box>
                </Stack>
              </Stack>
              <Stack gap={3}>
                <Stack
                  flexDirection={"row"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  gap={2}
                  sx={{ width: "500px" }}
                >
                  <Stack
                    flexDirection={"row"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    gap={2}
                    sx={{ width: "500px" }}
                  >
                    <Typography
                      fontSize={{ xs: "18px", sm: "38px" }}
                      fontWeight={"600"}
                      sx={{ whiteSpace: "nowrap" }}
                    >
                      Master :
                    </Typography>
                    <Autocomplete
                      // freeSolo
                      value={selectMasterData}
                      sx={{
                        width: 300,
                        backgroundColor: "#fff",
                        borderRadius: "10px",
                      }}
                      options={masterList}
                      isOptionEqualToValue={(option, value) =>
                        option.label === value.label
                      }
                      onChange={(event, value) => {
                        setSelectMasterData(value);
                        if (value.length > 0) {
                          // setMaster(value);
                          const ids = value.map((res) => res.id);
                          // formik.setFieldValue("student_ids", [value.id]);
                          formik.setFieldValue("master_student_ids", ids);
                        } else {
                          // setMaster("");
                          // setSelectMasterData([]);
                          // formik.setFieldValue("student_ids", []);
                          formik.setFieldValue("master_student_ids", []);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={"Select Master Child"}
                        />
                      )}
                      multiple
                      getOptionSelected={(option, value) =>
                        option.label === value.label
                      }
                    />
                  </Stack>
                </Stack>
                <Stack
                  flexDirection={"row"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  gap={2}
                  sx={{ width: "500px" }}
                >
                  <Stack
                    flexDirection={"row"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    gap={2}
                    sx={{ width: "500px" }}
                  >
                    <Typography
                      fontSize={{ xs: "18px", sm: "38px" }}
                      fontWeight={"600"}
                      sx={{ whiteSpace: "nowrap" }}
                    >
                      Linked :
                    </Typography>
                    <Autocomplete
                      sx={{
                        width: 300,
                        backgroundColor: "#fff",
                        borderRadius: "10px",
                      }}
                      value={selectLinkData}
                      options={LinkedList}
                      isOptionEqualToValue={(option, value) =>
                        option.label === value.label
                      }
                      onChange={(event, value) => {
                        setSelectLinkData(value);
                        if (value.length > 0) {
                          const ids = value.map((res) => res.id);
                          // formik.setFieldValue("master_student_ids", ids);
                          formik.setFieldValue("student_ids", ids);
                        } else {
                          // formik.setFieldValue("master_student_ids", []);
                          formik.setFieldValue("student_ids", []);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={"Select Master Child"}
                        />
                      )}
                      multiple
                      getOptionSelected={(option, value) =>
                        option.label === value.label
                      }
                    />
                  </Stack>

                </Stack>

              </Stack>
              <Grid item xs={12}>
                <Stack
                  flexDirection={"row"}
                  gap={{ xs: 2 }}
                  mt={2}
                  justifyContent={"center"}
                >
                  <Button
                    buttonName={"Save"}
                    loading={loading}
                    disabled={loading}
                    onClick={() => formik.handleSubmit()}
                  />
                  <Button
                    buttonName="Exit"
                    color="white"
                    onClick={() => navigate("/modifications")}
                  />
                </Stack>
              </Grid>
            </Stack>
          </Stack>

        </Grid>
        <Grid item xs={12}>
          <Stack flexDirection={"row"} gap={2} style={{ display: "flex", flexWrap: "wrap" }}>
            {selectMasterData && selectMasterData.length > 0 ? (
              selectMasterData.map((response) => {
                return (
                  <Stack justifyContent={"center"} alignItems={"center"} gap={1}>
                    <Stack
                      flexDirection={"row"}
                      justifyContent={"space-between"}
                      width={"100%"}
                      alignItems={"center"}
                    >
                      <Typography variant="h4" className="Gilroy_Bold">
                        Master
                      </Typography>
                      <IoClose
                        style={{
                          fontSize: "45px",
                          cursor: "pointer",
                          backgroundColor: "white",
                          margin: "6px",
                          color: "black",
                        }}
                        onClick={() => deleteMasterData(response.id)}
                      />
                    </Stack>
                    <img
                      src={
                        response.profilePicture
                          ? response.profilePicture
                          : Unknown
                      }
                      alt=""
                      width={250}
                      height={300}
                    />
                    <Typography variant="h3" className="Gilroy_Bold">
                      {response.label ? response.label : "-"}
                    </Typography>
                  </Stack>
                );
              })
            ) : (
              <> </>
            )}

            {selectLinkData && selectLinkData.length > 0 ? (
              selectLinkData.map((response) => {
                return (
                  <Stack justifyContent={"center"} alignItems={"center"} gap={1}>
                    <Stack
                      flexDirection={"row"}
                      justifyContent={"space-between"}
                      width={"100%"}
                      alignItems={"center"}
                    >
                      <Typography variant="h4" className="Gilroy_Bold">
                        Linked
                      </Typography>
                      <IoClose
                        style={{
                          fontSize: "45px",
                          cursor: "pointer",
                          backgroundColor: "white",
                          margin: "6px",
                          color: "black",
                        }}
                        onClick={() => deleteLinkData(response.id)}
                      />
                    </Stack>
                    <img
                      src={
                        response.profilePicture
                          ? response.profilePicture
                          : Unknown
                      }
                      alt=""
                      width={250}
                      height={300}
                    />
                    <Typography variant="h3" className="Gilroy_Bold">
                      {response.label ? response.label : "-"}
                    </Typography>
                  </Stack>
                );
              })
            ) : (
              <></>
            )}
          </Stack>
        </Grid>

      </Grid>

      <Modal
        modalOpen={isModalOpen}
        handleClose={() =>
          setIsModalOpen({
            open: false,
            currentComponent: "",
            para: "",
            head: "",
          })
        }
        closeFlag={closeFlagHandler}
      />
    </>
  );
}

export default GuardianPerson;
