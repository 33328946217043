import Pagination from "../../components/commons/Table/Pagination";
import { useStyles } from "../../helper/Theme";
import { useNavigate } from "react-router-dom";
import {
  Paper,
  Table,
  TableCell,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Stack,
} from "@mui/material";
import { Unknown } from "../../helper/Constant";
import { useEffect } from "react";
import { unKnownPersonDataHandeler } from "../../Service/UnknownPerson.service";
import { useState } from "react";
import { TableSkeleton } from "../../components/commons/Table";
import NoRecordFound from "../../components/commons/NoRecordFound";
import moment from "moment";
import { categoryList } from "../../helper/Helper";
import SubHeader from "../../components/commons/SubHeader";

export default function Index() {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [unKnownPersonData, setUnKnownPersonData] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [page, setPage] = useState(1);

  const navigate = useNavigate();

  const getUnknownDataHandler = async (page) => {
    try {
      setLoading(true);
      const { count, data, message, success, totalPage } =
        await unKnownPersonDataHandeler(page);
      console.log(data, "data");
      if (success) {
        setDataCount(count);
        setUnKnownPersonData(data);
      } else {
        setDataCount(0);
        setUnKnownPersonData([]);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const pageChangeHandler = (data) => {
    setPage(data);
    getUnknownDataHandler(data);
  };

  useEffect(() => {
    getUnknownDataHandler(page);
  }, []);

  return (
    <>

      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow className="MuiReportTable-root">
              <TableCell>SFI</TableCell>
              <TableCell>Image</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Entry</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableSkeleton rowsNum={10} colsNum={5} />
            ) : unKnownPersonData && unKnownPersonData.length > 0 ? (
              unKnownPersonData.map((response, i) => {
                const {
                  id,
                  created_at,
                  is_blacklisted,
                  username,
                  opt_out,
                  profile_image,
                  entry_time,
                  exit_time,
                } = response;
                return (
                  <TableRow
                    key={i}
                    className="MuiReportTable-root"
                    onClick={() => navigate("/add-person", {
                      state: {
                        personId: id,
                        personName: username,
                        responseData: { response },
                        categorey: "",
                        editFlage: false,
                        personImage: profile_image ? profile_image : "",
                        opt_out: opt_out
                      }
                    })}
                    sx={{ cursor: "pointer" }}
                  >
                    <TableCell>{username ? username : "-"}</TableCell>
                    <TableCell>
                      <img
                        src={profile_image ? profile_image : ""}
                        alt=""
                        width={100}
                        height={100}
                      />
                    </TableCell>
                    <TableCell>
                      {moment(created_at).format("DD-MM-YYYY")}
                    </TableCell>
                    <TableCell>{moment(created_at).format("HH:mm")}</TableCell>
                  </TableRow>
                );
              })
            ) : (
              <NoRecordFound />
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        pageNumber={page}
        count={dataCount}
        pageChangeHandler={pageChangeHandler}
      />
    </>
  );
}
