import Pagination from "../../components/commons/Table/Pagination";
import { useStyles } from "../../helper/Theme";
import {
    Paper,
    Table,
    TableCell,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    Stack,
    Autocomplete,
    TextField,
    Typography,
} from "@mui/material";
import { Unknown } from "../../helper/Constant";
import { DateRange, Input, Select } from "../../components/commons";
import { personList } from "../../helper/Helper";
import { useEffect, useState } from "react";
import { newPersonListHandler } from "../../Service/personList.service";
import { TableSkeleton } from "../../components/commons/Table";
import NoRecordFound from "../../components/commons/NoRecordFound";
import index from "../Settings";
import { getExceptionApiListHandler, getRoomDropDownList } from "../../Service/Exception.service";
import moment from "moment/moment";
import SubHeader from "../../components/commons/SubHeader";

export default function ExpAttEdu() {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [extendAttendData, setExtendAttendData] = useState([]);
    const [dataCount, setDataCount] = useState(0);
    const [page, setPage] = useState(1);
    const [personType, setPersonType] = useState("");
    const [personList, setPersonList] = useState([]);
    const [selectPerson, setSelectPerson] = useState("");
    const [roomListData, setRoomListData] = useState([]);
    const [selectedRoom, setSelectedRoom] = useState("");


    const getExceptionAttendListHandler = async (
        startDate,
        endDate,
        page,
        personType
    ) => {
        try {
            setLoading(true);
            const { count, data, message, success, totalPage } =
                await getExceptionApiListHandler(startDate, endDate, page, personType, selectedRoom, "educator");
            if (success) {
                setDataCount(count);
                setExtendAttendData(data);
            } else {
                setDataCount(0);
                setExtendAttendData([]);
            }
        } catch (err) {
            console.log(err, "Error");
        } finally {
            setLoading(false);
        }
    };

    const getPersonListHandler = async () => {
        try {
            const { count, data, message, success, totalPage } =
                await newPersonListHandler();
            if (success) {
                const personData =
                    data &&
                    data.map((res) => {
                        console.log("rreess", res);
                        return {
                            label: res.username,
                            id: res.id,
                        };
                    });
                setPersonList(personData);
            } else {
                setPersonList([]);
            }
        } catch (err) {
            console.error("error", err);
        }
    };

    const pageChangeHandler = (data) => {
        setPage(data);
        getExceptionAttendListHandler(startDate, endDate, data, personType);
    };

    const dataRanageHandler = (value) => {
        setStartDate(value.formDate);
        setEndDate(value.toDate);
    };

    const getroomListsData = async () => {
        const { count, data, message, success } = await getRoomDropDownList();
        if (success) {
            setRoomListData(data);
        }
    }

    const handleRoomChange = (event) => {
        setSelectedRoom(event.target.value);
    };

    useEffect(() => {
        getExceptionAttendListHandler(startDate, endDate, page, personType);
        getPersonListHandler();
        getroomListsData();
    }, [startDate, endDate, page, personType, selectedRoom]);


    return (
        <>
            <Stack flexDirection={"row"} gap={2} mb={2}>
                <Typography className="white-box" style={{ justifyContent: "center", fontWeight: "600", fontSize: "38px" }}>
                    SFI:-
                </Typography>
                <Autocomplete
                    freeSolo
                    value={selectPerson}
                    sx={{ width: 300, backgroundColor: "#fff", borderRadius: "10px" }}
                    options={personList}
                    onChange={(event, value) => {
                        if (value) {
                            setSelectPerson(value);
                            setPersonType(value?.id ? value?.id : "");
                        } else {
                            setSelectPerson("");
                            setPersonType(value?.id);
                        }
                    }}
                    renderInput={(params) => (
                        <TextField {...params} placeholder={"eg SFI"} />
                    )}
                />
                <Typography className="white-box" style={{ justifyContent: "center", fontWeight: "600", fontSize: "38px" }}>
                    Date:-
                </Typography>
                {/* <Input
                    placeholder="Select Date"
                    type="date"
                    style={{ height: "100%", fontSize: "38px" }}
                    onChange={(event) => setStartDate(event.target.value)}
                /> */}
                <DateRange dataHandler={dataRanageHandler} />
                {/* <Typography className="white-box" style={{ justifyContent: "center", fontWeight: "600", fontSize: "38px" }}>
                    Room :-
                </Typography>
                <Select
                    style={{ maxWidth: "300px" }}
                    selectHead="Select Room"
                    selectList={roomListData}
                    value={selectedRoom}
                    onChange={handleRoomChange}
                /> */}
            </Stack>
            <TableContainer component={Paper} className={classes.tableContainer}>
                <Table stickyHeader aria-label="simple table">
                    <TableHead>
                        <TableRow className="MuiReportTable-root">
                            <TableCell>SFI</TableCell>
                            <TableCell>Image</TableCell>
                            <TableCell>Date</TableCell>
                            <TableCell>Entry</TableCell>
                            <TableCell>Exit</TableCell>
                            <TableCell>Category</TableCell>
                            <TableCell>Comment</TableCell>
                            <TableCell>Adjustments</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading ? (
                            <TableSkeleton rowsNum={10} colsNum={6} />
                        ) : extendAttendData && extendAttendData.length > 0 ? (
                            extendAttendData.map((response, index) => {
                                const {
                                    alert_image,
                                    profile_image,
                                    username,
                                    alert_type,
                                    alert_type_name,
                                    blacklisted_person,
                                    blacklisted_person_username,
                                    created_at,
                                    delay_exit,
                                    category,
                                    device,
                                    educator,
                                    educator_username,
                                    guardian,
                                    guardian_username,
                                    id,
                                    location,
                                    adjustment,
                                    student,
                                    student_username,
                                    tread_person,
                                    tread_person_username,
                                    unknown_person,
                                    exit,
                                    user_image,
                                    unknown_person_username,
                                    comment,
                                    entry
                                } = response;
                                return (
                                    <TableRow
                                        key={index}
                                        className="MuiReportTable-root"
                                        sx={{ cursor: "pointer" }}
                                    >
                                        <TableCell>{username ? username : "-"}</TableCell>
                                        <TableCell>
                                            <img
                                                src={user_image ? user_image : ""}
                                                alt=""
                                                width={100}
                                                height={100}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            {moment(created_at).format("DD-MM-YYYY")}
                                        </TableCell>
                                        <TableCell>
                                            {entry || '-'}
                                        </TableCell>
                                        <TableCell>{exit ? exit : "-"}</TableCell>
                                        <TableCell>{category ? category : "-"}</TableCell>
                                        <TableCell>{comment ? comment : "-"}</TableCell>
                                        <TableCell>{adjustment ? adjustment : "-"}</TableCell>
                                    </TableRow>
                                );
                            })
                        ) : (
                            <NoRecordFound colSpan={7} />
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <Pagination
                pageNumber={page}
                count={dataCount}
                pageChangeHandler={pageChangeHandler}
            />
        </>
    );
}
