import { DELETE, get, patch, post, put } from "../Web.request";

export const classListHandler = (search, page) => {
  return get(
    `${process.env.REACT_APP_API_URL}/users/division_data?search=${search}&page=${page}`
  );
};

export const deleteClassById = (id) => {
  return DELETE(
    `${process.env.REACT_APP_API_URL}/users/delete_division/${id}/`
  );
};

export const createClassHandler = (payload) => {
  return post(
    `${process.env.REACT_APP_API_URL}/users/create_division`,
    payload
  );
};

export const updateClassHandler = (id, payload) => {
  return patch(
    `${process.env.REACT_APP_API_URL}/users/division_update/${id}/`,
    payload
  );
};


export const getClassByStandarId  = (id) =>{
  return get(`${process.env.REACT_APP_API_URL}/users/division?search=${id}`);
};