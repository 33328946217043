import { DELETE, get, patch, post, put } from "../Web.request";

export const getEnrolmentHours = (search) => {
    return get(
        `${process.env.REACT_APP_API_URL}/users/master_enrolment_hours`
    );
};

export const updateEnrolmentHours = (payload) => {
    return patch(`${process.env.REACT_APP_API_URL}/users/master_enrolment_hours`, payload);
};

export const getELCDetails = (search) => {
    return get(
        `${process.env.REACT_APP_API_URL}/users/organization_data`
    );
};

export const updateELCDetails = (payload) => {
    return patch(`${process.env.REACT_APP_API_URL}/users/organization_data`, payload);
};

export const createNewUser = (payload) => {
    return post(`${process.env.REACT_APP_API_URL}/users/custom_user_creation_api`, payload);
};

export const getNewUsersList = () => {
    return get(`${process.env.REACT_APP_API_URL}/users/custom_user_creation_api`);
};

export const addNewbannedPserson = (payload) => {
    return post(`${process.env.REACT_APP_API_URL}/users/banned_person_register`, payload);
};

export const listBannedPersons = () => {
    return get(`${process.env.REACT_APP_API_URL}/users/banned_person_register`);
};

export const createANewRoom = (payload) => {
    return post(`${process.env.REACT_APP_API_URL}/users/standard_data `, payload);
};

export const updateRoomData = (payload, roomId) => {
    return patch(`${process.env.REACT_APP_API_URL}/users/standard_update/${roomId}`, payload);
};

export const listCreatedRooms = () => {
    return get(`${process.env.REACT_APP_API_URL}/users/standard_data `);
};

export const deleteUser = (id) => {
    return DELETE(`${process.env.REACT_APP_API_URL}/users/custom_user_deletion_api/${id}`);
};