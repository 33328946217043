import React, { useEffect, useState } from "react";
import {
  Select,
  MenuItem,
  TextField,
  Box,
  Typography,
  Grid,
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
} from "@mui/material";
import { FaPlus } from "react-icons/fa";
import {
  getEnrolmentHours,
  updateEnrolmentHours,
  getELCDetails,
  updateELCDetails,
  createNewUser,
  getNewUsersList,
  addNewbannedPserson,
  listBannedPersons,
  createANewRoom,
  listCreatedRooms,
  updateRoomData,
  deleteUser
} from "../../Service/settings.service";
import { AutoComplete, Button, Input } from "../../components/commons";
import {
  errorNotification,
  successNotification,
} from "../../helper/Notification";
// import Modal from '@mui/material/Modal';
import { Modal } from "../../components/commons";


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function SettingsTab() {
  const [normalEnrolmentHours, setNormalEnrolmentHours] = useState([]);
  const [loading, setLoading] = useState(false);
  const [ELCData, setELCData] = useState({
    serialNo: "",
    Elcname: "",
    duration_days: "",
    educator_allowance_time: "",
    student_allowance_time: "",
  });
  const [userData, setUserData] = useState({
    username: "",
    password: "",
  });
  const [userRoomData, setuserRoomData] = useState({
    name: "",
    age_range: 0,
  });
  const [userDataList, setUserDataList] = useState([]);
  const [bannedPersonImage, setbannedPersonImage] = useState({});
  const [bannedPersonsList, setBannedPersonsList] = useState([]);
  const [classRoomList, setclassRoomList] = useState([]);
  const [selectedUserDelete, setSelectedUserDelete] = useState(null);
  const [editSelectedID, setEditSelectedID] = useState(null);

  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
    para: "",
    head: "",
  });

  const manageNormalHours = async () => {
    const { count, data, message, success, totalPage } =
      await getEnrolmentHours();
    if (success) {
      setNormalEnrolmentHours(data);
    }
  };

  const handleChange = (e, index, field) => {
    normalEnrolmentHours[index][field] = e.target.value;
    const neItems = [...normalEnrolmentHours];
    setNormalEnrolmentHours(neItems);
  };

  const updateHours = async () => {
    setLoading(true);
    const payload = {
      enrollment_data: normalEnrolmentHours,
    };
    const { data, message, success } = await updateEnrolmentHours(payload);
    if (success) {
      setLoading(false);
      successNotification(message);
    } else {
      errorNotification(message);
    }
  };

  const handleGetELCDetails = async () => {
    const { count, data, message, success, totalPage } = await getELCDetails();
    if (success) {
      setELCData({
        serialNo: data.registration_no,
        Elcname: data.org_name,
        duration_days: data.duration_days,
        educator_allowance_time: data.educator_allowance_time,
        student_allowance_time: data.student_allowance_time,
      });
    }
  };

  const closeFlagHandler = () => {
    setIsModalOpen({
      open: false,
      currentComponent: "",
      para: "",
      head: "",
    })
  };


  const handelUpdateELCUpdate = (e, key) => {
    setELCData({
      ...ELCData,
      [key]: e.target.value,
    });
  };

  const handelUpdateRoomData = (e, key) => {
    setuserRoomData({
      ...userRoomData,
      [key]: e.target.value,
    });
  };

  const submitRoomData = async () => {
    // createANewRoom
    const payload = {
      name: userRoomData.name,
      age_range: userRoomData.age_range,
    };
    // updateRoomData
    const { data, message, success } = editSelectedID == null ? await createANewRoom(payload) : await updateRoomData(payload, editSelectedID);
    if (success) {
      getAllClassListes();
      setEditSelectedID(null);
      setuserRoomData({
        name: "",
        age_range: 0,
      });
      successNotification(message);
    } else {
      errorNotification(message);
    }
  };

  const handelUserUpdate = (e, key) => {
    setUserData({
      ...userData,
      [key]: e.target.value,
    });
  };

  const getCreatedUsers = async () => {
    const { count, data, message, success, totalPage } =
      await getNewUsersList();
    if (success) {
      setUserDataList(data);
    }
  };

  const getAllBannedPersons = async () => {
    const { count, data, message, success, totalPage } =
      await listBannedPersons();
    if (success) {
      setBannedPersonsList(data);
    }
  };

  const getAllClassListes = async () => {
    const { count, data, message, success, totalPage } =
      await listCreatedRooms();
    if (success) {
      setclassRoomList(data);
    }
  };

  useEffect(() => {
    manageNormalHours();
    handleGetELCDetails();
    getCreatedUsers();
    getAllBannedPersons();
    getAllClassListes();
  }, []);

  const submitUserData = async () => {
    const { data, message, success } = await createNewUser(userData);
    if (success) {
      setUserData({
        username: "",
        password: "",
      });
      getCreatedUsers();
      successNotification(message);
    } else {
      errorNotification(message);
    }
  };

  const saveELCData = async () => {
    const payload = {
      org_name: ELCData.Elcname,
      registration_no: ELCData.serialNo,
      duration_days: ELCData.duration_days,
      educator_allowance_time: ELCData.educator_allowance_time,
      student_allowance_time: ELCData.student_allowance_time,
    };
    const { data, message, success } = await updateELCDetails(payload);
    if (success) {
      handleGetELCDetails();
      successNotification(message);
    } else {
      errorNotification(message);
    }
  };

  const manageBannedPersonImage = (e) => {
    const singleFile = e.target.files;
    if (singleFile && singleFile.length > 0) {
      setbannedPersonImage(singleFile[0]);
    }
  };

  const submitBannedPerson = async () => {
    if (bannedPersonImage) {
      const formData = new FormData();
      formData.append("profile_image", bannedPersonImage);
      const { data, message, success } = await addNewbannedPserson(formData);
      if (success) {
        getAllBannedPersons();
        successNotification(message);
      } else {
        errorNotification(message);
      }
    } else {
      errorNotification("Please select image");
    }
  };

  const manageDeleteUser = async (id) => {
    console.log('---------------------', id);
    setSelectedUserDelete(id);
    setOpen(true)
  }

  // const confirmDeleteUser = async () => {
  //   if (selectedUserDelete) {
  //     const { data, message, success } = await deleteUser(selectedUserDelete);
  //     if (success) {
  //       setSelectedUserDelete(null);
  //       getCreatedUsers();
  //       setOpen(false);
  //       successNotification(message);
  //     } else {
  //       errorNotification(message);
  //     }
  //   }
  // }

  const clearTextBoxValue = (txtName) => {
    setUserData({
      ...userData,
      [txtName]: "",
    });
  }

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleRoomEdit = (room) => {
    console.log('rooms ****************', room);
    if (room) {
      setuserRoomData({
        name: room?.name,
        age_range: room?.age_range,
      })
      setEditSelectedID(room.id)
    } else {
      errorNotification("Please select room");
    }


  }

  return (
    <>
      <div
        style={{
          display: "flex",
          gap: "10px",
          justifyContent: "center",
          marginTop: "-80px",
        }}
      >
        <Typography
          fontSize={{ xs: "15px", sm: "25px" }}
          fontWeight={"600"}
          sx={{ whiteSpace: "nowrap" }}
          className="white-box"
        >
          Serial Number :-
        </Typography>
        <Box>
          {/* <Input aria-label="Demo input" placeholder="1234678" style={{backgroundColor:"white"}} /> */}
          <input
            placeholder="1234678"
            // disabled={true}
            className="white-box"
            style={{
              borderColor: "white",
              width: "fit-content",
              border: "0px",
              height: "100%",
              fontSize: "25px",
            }}
            value={ELCData.serialNo}
            onChange={(e) => handelUpdateELCUpdate(e, "serialNo")}
            onBlur={saveELCData}
          />
        </Box>
      </div>
      <div
        style={{
          display: "flex",
          gap: "30px",
          justifyContent: "start",
          marginTop: "20px",
        }}
      >
        <Typography
          fontSize={{ xs: "18px", sm: "25px" }}
          fontWeight={"600"}
          sx={{ whiteSpace: "nowrap", paddingRight: "40px" }}
          className="white-box"
        >
          ELC Name :-
        </Typography>

        <Box>
          {/* <Input aria-label="Demo input" placeholder="1234678" style={{backgroundColor:"white"}} /> */}
          <input
            className="white-box"
            placeholder="ABC Early Learning Center"
            style={{
              borderColor: "white",
              width: "30rem",
              border: "0px",
              height: "100%",
              fontSize: "22px",
            }}
            value={ELCData.Elcname}
            onChange={(e) => handelUpdateELCUpdate(e, "Elcname")}
            onBlur={saveELCData}
          />
        </Box>
      </div>
      <Grid style={{ display: "flex", gap: "10px" }}>
        <div
          style={{
            display: "flex",
            gap: "30px",
            justifyContent: "start",
            marginTop: "20px",
            alignItems: "center",
          }}
        >
          <Typography
            fontSize={{ xs: "18px", sm: "25px" }}
            fontWeight={"600"}
            sx={{ whiteSpace: "nowrap", paddingRight: "40px" }}
            className="white-box"
          >
            User Name :-
          </Typography>
          <Box style={{ display: "flex", alignItems: "center" }}>
            {/* <Input aria-label="Demo input" placeholder="1234678" style={{backgroundColor:"white"}} /> */}
            <input
              className="white-box"
              placeholder="Enter User Name"
              style={{
                borderColor: "white",
                width: "100%",
                border: "0px",
                height: "100%",
                fontSize: "22px",
              }}
              value={userData.username}
              onChange={(e) => handelUserUpdate(e, "username")}
            />
            <Box sx={{ paddingRight: "20px" }} mt={1}>
              <button className="Dotdeletebtn" onClick={() => clearTextBoxValue("username")}>...</button>
            </Box>
          </Box>
        </div>
        <Box
          style={{
            display: "flex",
            gap: "30px",
            marginTop: "20px",
            alignItems: "center",
          }}
        >
          <Typography
            fontSize={{ xs: "18px", sm: "25px" }}
            fontWeight={"600"}
            sx={{ whiteSpace: "nowrap", paddingRight: "40px" }}
            className="white-box"
          >
            User Password :-
          </Typography>
          <Box style={{ display: "flex", alignItems: "center" }}>
            {/* <Input aria-label="Demo input" placeholder="1234678" style={{backgroundColor:"white"}} /> */}
            <input
              placeholder="Password"
              className="white-box"
              style={{
                borderColor: "white",
                width: "100%",
                border: "0px",
                height: "100%",
                fontSize: "22px",
              }}
              autoComplete="new-password"
              value={userData.password}
              type="password"
              onChange={(e) => handelUserUpdate(e, "password")}
            />
            <Box sx={{ paddingRight: "5px" }}>
              <button className="Dotdeletebtn" onClick={() => clearTextBoxValue("password")}>...</button>
            </Box>
          </Box>
          <div>
            <FaPlus
              style={{ width: "35px", height: "35px", cursor: "pointer" }}
              onClick={() => submitUserData()}
            />
          </div>
        </Box>
      </Grid>
      {/* <Grid
        style={{ display: "flex", flexDirection: "row", marginTop: "5px" }}
      >
        <div>
          {userDataList
            ? userDataList.map((item) => (
              <div
                style={{
                  display: "flex",
                  gap: "1px",
                  backgroundColor: "white",
                  alignItems: "center",
                }}
              >
                <Typography
                  fontSize={{ xs: "18px", sm: "23px" }}
                  fontWeight={"600"}
                  sx={{ whiteSpace: "nowrap", paddingRight: "40px" }}
                  className="white-box"
                >
                  User Name :-
                </Typography>

                <Box>
                  <input
                    className="white-box"
                    placeholder="User Name"
                    style={{
                      borderColor: "white",
                      width: "100%",
                      border: "0px",
                      height: "100%",
                      fontSize: "23px",
                    }}
                    value={`${item.username} `}
                    readOnly={true}
                  />
                </Box>
                <Box sx={{ paddingRight: "20px" }} mt={1} mb={1}>
                  <button className="Dotdeletebtn" onClick={() => manageDeleteUser(item.id)}>...</button>
                </Box>
              </div>
            ))
            : ""}
        </div>
      </Grid> */}
      <Grid
        style={{
          display: "flex",
          gap: "30px",
          marginTop: "40px",
          justifyContent: "space-evenly",
          flexWrap: "wrap",
        }}
      >
        {/* table */}
        <Grid
          style={{
            border: "4px solid #00BFF5",
            padding: "10px",
            height: "fit-content",
          }}
        >
          <Typography
            fontSize={{ xs: "18px", sm: "23px" }}
            fontWeight={"600"}
            sx={{
              whiteSpace: "nowrap",
              justifyContent: "center",
              alignItems: "center",
            }}
            className="white-box"
          >
            Normal Hours:-
          </Typography>
          <Grid item xs={4} sx={{ display: "flex", alignItems: "flex-end" }}>
            <Box sx={{ width: "min-content" }}>
              <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Table style={{ marginTop: "20px" }}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Day</TableCell>
                      <TableCell style={{ paddingLeft: "10px" }}>
                        Open
                      </TableCell>
                      <TableCell style={{ paddingRight: "10px" }}> </TableCell>
                      <TableCell>Close</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {normalEnrolmentHours.map((response, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell className="white-box">
                            {response.day}
                          </TableCell>
                          <TableCell>
                            <input
                              style={{ marginLeft: "10px" }}
                              type="time"
                              value={response.entry_time}
                              onChange={(e) =>
                                handleChange(e, index, "entry_time")
                              }
                            />
                          </TableCell>

                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              fontSize: "22px",
                              fontWeight: "900",
                            }}
                          >
                            -
                          </Box>
                          <TableCell>
                            <input
                              type="time"
                              value={response.exit_time}
                              onChange={(e) =>
                                handleChange(e, index, "exit_time")
                              }
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </Grid>
            </Box>
          </Grid>

          <Box>
            <button
              style={{
                backgroundColor: "#00BFF5",
                padding: "8px",
                borderRadius: "7px",
                color: "white",
                marginTop: "10px",
                border: "none",
                fontSize: "20px",
              }}
              loading={loading}
              disabled={loading}
              onClick={() => updateHours()}
            >
              Update
            </button>
          </Box>
        </Grid>
        {/* room */}
        <Grid
          style={{
            border: "4px solid #00BFF5",
            padding: "20px",
            height: "fit-content",
          }}
        >
          <Box style={{ display: "flex", alignItems: "center" }}>
            <Typography
              width={"100%"}
              component="span"
              className="white-box"
              fontWeight={600}
              fontSize={25}
              sx={{
                display: "flex",
                justifyContent: "center",
                marginBottom: 2,
              }}
            >
              Room Names:-
            </Typography>

            <FaPlus
              // onClick={addRoom}
              style={{
                width: "35px",
                height: "35px",
                cursor: "pointer",
                marginLeft: "25px",
                marginTop: "-17px",
              }}
              onClick={() => submitRoomData()}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
              marginBottom: 2,
            }}
          >
            <Box>
              <input
                placeholder="Room Name"
                style={{
                  width: "200px",
                  border: "0px",
                  // height: "87px",
                  fontSize: "25px",
                }}
                className="white-box"
                value={userRoomData.name}
                onChange={(e) => handelUpdateRoomData(e, "name")}
                name="name"
              />
            </Box>
            <Select
              defaultValue={0}
              label="Age Group"
              style={{ fontSize: "25px", width: "300px" }}
              value={userRoomData.age_range}
              onChange={(e) => handelUpdateRoomData(e, "age_range")}
              name="age_range"
            >
              <MenuItem value={0} disabled>
                Age Group
              </MenuItem>
              <MenuItem value={"0-36 Months"}>0-36 Months</MenuItem>
              <MenuItem value={"36-60 Months"}>36-60 Months</MenuItem>
              <MenuItem value={"60+ Months"}>60+ Months</MenuItem>
            </Select>
          </Box>
          <Box>
            {classRoomList && classRoomList.length > 0
              ? classRoomList.map((room, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                    marginBottom: 2,
                  }}
                >
                  <div
                    className="white-box"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      // gap: "180px",
                      width: "100%",
                    }}
                  >
                    <Typography fontWeight={600} fontSize={20}>
                      {room?.name}
                    </Typography>
                    <Typography fontWeight={600} fontSize={20}>
                      {room?.age_range}
                    </Typography>
                    <Typography >
                      <button
                        style={{
                          backgroundColor: "#00BFF5",
                          padding: "5px",
                          borderRadius: "7px",
                          color: "white",
                          // marginTop: "10px",
                          border: "none",
                          fontSize: "20px",
                        }}
                        loading={loading}
                        disabled={loading}
                        onClick={() => handleRoomEdit(room)}
                      >
                        Edit
                      </button>
                    </Typography>
                    <Typography >
                      <button
                        style={{
                          backgroundColor: "red",
                          padding: "5px",
                          borderRadius: "7px",
                          color: "white",
                          // marginTop: "10px",
                          border: "none",
                          fontSize: "20px",
                        }}
                        loading={loading}
                        disabled={loading}
                        onClick={() => setIsModalOpen({
                          open: true,
                          currentComponent: "deleteRoomSetting",
                          para: "Are you sure want to delete room?",
                          head: "Delete Room",
                          id: room.id,
                          name: "DELETEROOMSETTING",
                        })}
                      >
                        Delete
                      </button>
                    </Typography>
                  </div>
                </Box>
              ))
              : ""}
          </Box>
        </Grid>
      </Grid>
      <Grid
        style={{
          display: "flex",
          gap: "50px",
          marginTop: "40px",
          justifyContent: "space-between",
        }}
      >
        <Box style={{ display: "flex", gap: "100px" }}>
          <Box style={{ display: "flex", gap: "10px" }}>
            <Typography
              fontSize={{ xs: "15px", sm: "25px" }}
              fontWeight={"600"}
              sx={{ whiteSpace: "nowrap", justifyContent: "center" }}
              className="white-box"
            >
              Daily Child Early/Late Time allowance:-
            </Typography>

            <Box>
              <input
                className="white-box"
                placeholder="XX minis"
                style={{
                  borderColor: "white",
                  width: "200px",
                  border: "0px",
                  height: "100%",
                  fontSize: "25px",
                }}
                value={ELCData.student_allowance_time}
                onChange={(e) =>
                  handelUpdateELCUpdate(e, "student_allowance_time")
                }
                onBlur={saveELCData}
                type="time"
              />
            </Box>
          </Box>
          <Box style={{ display: "flex", gap: "10px" }}>
            <Typography
              fontSize={{ xs: "18px", sm: "25px" }}
              fontWeight={"600"}
              sx={{ whiteSpace: "nowrap", justifyContent: "center" }}
              className="white-box"
            >
              Weekly Educator Early/Late Time allowance:-
            </Typography>
            <Box>
              <input
                className="white-box"
                placeholder="XX minis"
                style={{
                  borderColor: "white",
                  // width: "200px",
                  border: "0px",
                  height: "100%",
                  fontSize: "20px",
                }}
                value={ELCData.educator_allowance_time}
                type="time"
                onChange={(e) =>
                  handelUpdateELCUpdate(e, "educator_allowance_time")
                }
                onBlur={saveELCData}
              />
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid
        style={{
          display: "flex",
          gap: "50px",
          marginTop: "40px",
          justifyContent: "space-between",
        }}
      >
        <Box style={{ display: "flex", gap: "80px" }}>
          <Box style={{ display: "flex", gap: "10px" }}>
            <Typography
              fontSize={{ xs: "18px", sm: "25px" }}
              fontWeight={"600"}
              sx={{
                whiteSpace: "nowrap",
                justifyContent: "center",
                height: "fit-content",
              }}
              className="white-box"
            >
              Keep data for how many days:-
            </Typography>

            <Box>
              <input
                className="white-box"
                placeholder="120 days"
                style={{
                  borderColor: "white",
                  width: "200px",
                  border: "0px",
                  marginLeft: "100px",
                  height: "fit-content",
                  fontSize: "25px",
                }}
                value={ELCData.duration_days}
                onChange={(e) => handelUpdateELCUpdate(e, "duration_days")}
                onBlur={saveELCData}
              />
            </Box>
          </Box>
          <Grid
            style={{ display: "flex", gap: "10px", flexDirection: "column" }}
          >
            <Box style={{ display: "flex", gap: "10px" }}>
              <Typography
                fontSize={{ xs: "18px", sm: "25px" }}
                fontWeight={"600"}
                sx={{
                  whiteSpace: "nowrap",
                  justifyContent: "center",
                  height: "fit-content",
                }}
                className="white-box"
              >
                Add image of a Banned person:-
              </Typography>
              <Box>
                <input
                  className="white-box"
                  type="file"
                  onChange={manageBannedPersonImage}
                  accept="image/*"
                  style={{
                    borderColor: "white",
                    width: "250px",
                    border: "0px",
                    marginLeft: "30px",
                    height: "fit-content",
                    fontSize: "20px",
                  }}
                />
              </Box>
              <Button
                style={{
                  backgroundColor: "#00BFF5",
                  // padding: "8px",
                  // width: "50px",
                  borderRadius: "7px",
                  color: "white",
                  // marginTop: "10px",
                  border: "none",
                  fontSize: "14px !important",
                }}
                buttonName={<FaPlus />}
                loading={loading}
                disabled={loading}
                onClick={() => submitBannedPerson()}
              />
            </Box>
            <Box
              style={{
                display: "flex",
                gap: "20px",
                maxWidth: "600px",
                flexWrap: "wrap",
              }}
            >
              {bannedPersonsList && bannedPersonsList.length > 0
                ? bannedPersonsList.map((img) => (
                  <img
                    src={img?.profile_image}
                    style={{ width: "100px", height: "100px" }}
                    alt="photo"
                  />
                ))
                : ""}
            </Box>
          </Grid>
        </Box>
      </Grid>


      {/* <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >

        <Box sx={style} style={{ display: "flex", flexDirection: "column", alignItems: "start", gap: "15px", }}>
          <Typography id="modal-modal-title" variant="h5" component="h2">
            Are you sure to delete user?
          </Typography>
          
          <Box sx={{ display: "flex", flexDirection: "row", gap: "10px" }}>

            <Button
              style={{
                backgroundColor: "red !important",
                padding: "18px",
                borderRadius: "7px",
                color: "white",
                // marginTop: "10px",
                border: "none",
                fontSize: "20px !important",
              }}
              buttonName={"Confirm"}

              onClick={() => confirmDeleteUser()}
            />
            <Button
              style={{
                backgroundColor: "#00BFF5",
                padding: "18px",
                borderRadius: "7px",
                color: "white",
                // marginTop: "10px",
                border: "none",
                fontSize: "20px !important",
              }}
              buttonName={"Cancel"}

              onClick={() => setOpen(false)}
            />

          </Box>
        </Box>
      </Modal> */}

      <Modal
        modalOpen={isModalOpen}
        listUpdate={getAllClassListes}
        handleClose={() =>
          setIsModalOpen({
            open: false,
            currentComponent: "",
            para: "",
            head: "",
          })
        }
        closeFlag={closeFlagHandler}
      />
    </>
  );
}
