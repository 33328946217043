import React from 'react'
import { DialogActions, DialogContent } from '@mui/material'
import { Button } from '../commons'
import { LiveImage } from '../../helper/Constant'

const LiveImgCapture = ({ handleClose , closeFlag }) => {
    return (
        <>
            <DialogContent>
                <img src={LiveImage} alt='' width={'100%'} />
            </DialogContent>
            <DialogActions sx={{ p: '0 24px 16px' }}>
                <Button buttonName='Cancel' size='small' color='white' onClick={handleClose} />
                <Button buttonName='Submit' size='small' onClick={handleClose} />
            </DialogActions>
        </>
    )
}

export default LiveImgCapture