import * as React from "react";
import Dialog from "@mui/material/Dialog";
import { ChangePassword, Delete, Logout, LiveImgCapture } from "../modals";
import AddDevice from "../accountMgt/modal/AddDevice";
import { Confirmation } from "../dataMgt/modal";
import AddStandard from "../masters/AddStandard";
import AddClass from "../masters/AddClass";
import AddSubject from "../Subject/AddSubject";
import AddLocation from "../Locations/AddLocation";

export default function CommonModal({ handleClose, modalOpen, closeFlag, listUpdate }) {
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="Common_modal"
      open={modalOpen.open}
    >
      {modalOpen.currentComponent === "changePassword" && (
        <ChangePassword
          handleClose={handleClose}
          modalOpen={modalOpen}
          closeFlag={closeFlag}
        />
      )}
      {modalOpen.currentComponent === "logout" && (
        <Logout
          handleClose={handleClose}
          modalOpen={modalOpen}
          closeFlag={closeFlag}
        />
      )}
      {modalOpen.currentComponent === "delete" && (
        <Delete
          handleClose={handleClose}
          modalOpen={modalOpen}
          closeFlag={closeFlag}
          isDeleteNewChild={false}
        />
      )}
      {modalOpen.currentComponent === "addDevice" && (
        <AddDevice
          handleClose={handleClose}
          modalOpen={modalOpen}
          closeFlag={closeFlag}
        />
      )}
      {modalOpen.currentComponent === "liveImgCapture" && (
        <LiveImgCapture
          handleClose={handleClose}
          modalOpen={modalOpen}
          closeFlag={closeFlag}
        />
      )}

      {modalOpen.currentComponent === "confirmation" && (
        <Confirmation
          handleClose={handleClose}
          modalOpen={modalOpen}
          closeFlag={closeFlag}
        />
      )}

      {modalOpen.currentComponent === "addStandard" && (
        <AddStandard
          handleClose={handleClose}
          modalOpen={modalOpen}
          closeFlag={closeFlag}
        />
      )}

      {modalOpen.currentComponent === "addClass" && (
        <AddClass
          handleClose={handleClose}
          modalOpen={modalOpen}
          closeFlag={closeFlag}
        />
      )}
      {modalOpen.currentComponent === "addSubject" && (
        <AddSubject
          handleClose={handleClose}
          modalOpen={modalOpen}
          closeFlag={closeFlag}
        />
      )}
      {modalOpen.currentComponent === "addLocation" && (
        <AddLocation
          handleClose={handleClose}
          modalOpen={modalOpen}
          closeFlag={closeFlag}
        />
      )}
      {modalOpen.currentComponent === "deleteChild" && (
        <Delete
          handleClose={handleClose}
          modalOpen={modalOpen}
          closeFlag={closeFlag}
          isDeleteNewChild={true}
        />
      )}

      {modalOpen.currentComponent === "deleteRoomSetting" && (
        <Delete
          handleClose={handleClose}
          modalOpen={modalOpen}
          closeFlag={closeFlag}
          isDeleteNewChild={false}
          listUpdate={listUpdate}
        />
      )}
    </Dialog>
  );
}
