import { DELETE, get, patch, post, put } from "../Web.request";

export const studentListHandler = (search, page, startDate, endDate, standardId) => {
  return get(
    `${process.env.REACT_APP_API_URL}/users/student_data?search=${search}&standard_id=${standardId}&start_date=${startDate}&end_date=${endDate}&page=${page}`
  );
};

export const deleteStudentById = (id) => {
  return DELETE(`${process.env.REACT_APP_API_URL}/users/delete_student/${id}/`);
};

export const deleteStudentByIdNew = (id) => {
  return DELETE(`${process.env.REACT_APP_API_URL}/users/person_delete_api/${id}`);
};

export const createStudentHandler = (payload) => {
  return post(`${process.env.REACT_APP_API_URL}/users/create_student`, payload);
};

export const updateStudentHandler = (id, payload) => {
  return patch(`${process.env.REACT_APP_API_URL}/users/student_update/${id}/`, payload)
};


export const getAllStudentDataApiHandler = () => {
  return get(`${process.env.REACT_APP_API_URL}/users/student`)
};