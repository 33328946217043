import { get, post, put, patch } from "../Web.request";

// modification for all categories to add person

export const modificationToPerson = (id, payload) => {
  return post(
    `${process.env.REACT_APP_API_URL}/users/add_gurdian_for_detected_person/${id}`,
    payload
  );
};

// get Modification api Handler

export const getModificationListApiHandler = (
  page,
  startDate,
  endDate,
  search,
  personType
) => {
  return get(
    `${process.env.REACT_APP_API_URL}/users/view_gurdian_for_detected_person?page=${page}&start_date=${startDate}&end_date=${endDate}&search=${search}&person_id=${personType || ""}`
  );
};

export const updateModificationApihandler = (id, payload) => {
  return patch(`${process.env.REACT_APP_API_URL}/users/update_gurdian_for_detected_person/${id}`, payload);
};
