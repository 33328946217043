import React, { useEffect, useState } from "react";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";
import { CloseIcon } from "../../helper/Icons";
import { Button, Input } from "../commons";
import { useFormik } from "formik";
import { standardIv } from "../../helper/intialValues";
import { standardValidation } from "../../helper/Validation";
import {
  createStandardHandler,
  getStandardAllList,
  updateStandardHandler,
} from "../../Service/Standard.service";
import {
  errorNotification,
  successNotification,
} from "../../helper/Notification";

const AddStandard = ({ handleClose, closeFlag, modalOpen }) => {
  const drawerWidth = 250;
  const response = modalOpen?.data?.totalData;
  const [errorDisplayFlag, setErrorDisplayFlag] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);

  const submitHandler = async () => {
    try {
      setLoading(true);
      // updateStandardHandler
      const { data, message, success } = modalOpen.isEdit
        ? await updateStandardHandler(response.id, formik.values)
        : await createStandardHandler(formik.values);
      if (success) {
        successNotification(message);
      } else {
        errorNotification(message);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(true);
      closeFlag();
      handleClose();
    }
  };

  const formik = useFormik({
    initialValues: standardIv,
    validationSchema: standardValidation,
    onSubmit: submitHandler,
  });

  const getStandardData = async () => {
    try {
      setDataLoading(true);
      const { data, message, success } = await getStandardAllList();
    } catch (err) {
      console.error(err);
    } finally {
      setDataLoading(false);
    }
  };

  useEffect(() => {
    getStandardData();
  }, []);

  useEffect(() => {
    if (modalOpen.isEdit) {
      getStandardDetails(modalOpen?.data?.totalData);
    }
  }, []);

  const getStandardDetails = (data) => {
    formik.setValues({
      ...formik.values,
      name: data.name,
    });
  };

  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
      {modalOpen.isEdit ? 'Edit' : 'Add'} Standard
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Grid container gap={2} width={{ sm: "400px" }} paddingY={1}>
          <Grid item xs={12}>
            <Input
              labelinput="Standard"
              errorDisplay={errorDisplayFlag}
              name="name"
              inputProps={{ maxLength: 15 }}
              formik={formik}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          buttonName="Cancel"
          size="small"
          color="white"
          onClick={handleClose}
        />
        <Button
          buttonName="Save"
          size="small"
          loading={loading}
          onClick={() => [formik.handleSubmit(), setErrorDisplayFlag(true)]}
        />
      </DialogActions>
    </>
  );
};

export default AddStandard;
