import React, { useState } from "react";
import {
  Box,
  ListItem,
  ListItemButton,
  ListItemIcon,
  List,
  ListItemText,
  Stack,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { SidebarList } from "../../helper/Helper";
import { Logo, LogoutBlue, sidebar_logo } from "../../helper/Constant";
import { Modal } from "../commons";

const Sidebar = ({ handleDrawerToggle, drawerWidth, drawerWidthsmall }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
    para: "",
    head: "",
  });

  return (
    <Box
      width={{}}
      sx={{ transition: "width 0.3s ease-in-out",  }}
      overflow={'auto'}
    >
      <List sx={{ padding: "10px", }}>
        <img src={sidebar_logo} alt="main_logo" width={"100%"} />
      </List>
      {/* height={"calc(100% - 200px)"} */}
      <Stack justifyContent={"space-between"}   style={{border:"4px solid #00BFF5",backgroundColor:"white"}}>
        <Box marginTop={3}  >
          {SidebarList.map((data, index) => {
            const isActive =
              location.pathname === data.path ||
              (data.path === "/data" &&
                [
                  "/data/details",
                  "/addstudent",
                  "/addguardian",
                ].includes(location.pathname)) ||
              (data.path === "/staff" &&
                location.pathname === "/staff/addstaff");

            return (
              <List sx={{ padding: "0" }} key={index}>
                <ListItem
                  onClick={() => navigate(data.path)}
                  disablePadding
                  sx={{
                    margin: { xs: "10px 0", sm: "15px 0" },
                    width: "unset",
                  }}
                >
                  <ListItemButton
                    onClick={handleDrawerToggle}
                    sx={{
                      // borderRadius: "0 35px 35px 0",
                      padding: {
                        xs: "9px 15px 9px 15px",
                        sm: "13px 20px 13px 25px",
                      },
                      backgroundColor: isActive ? "primary.main" : "",
                      ".sidebar_text": {
                        color: isActive ? "white.main" : "secondary.main",
                      },
                      transition: "width 0.3s ease-in-out",
                    }}
                    className={isActive ? "active_sidebar" : ""}
                  >
                    {/* <ListItemIcon
                      sx={{
                        minWidth: { xs: 30, sm: 35 },
                        width: { xs: "7px", sm: "11px" },
                        height: { xs: "14px", sm: "18px" },
                      }}
                    >
                      <img
                        className="sidebar_image"
                        src={isActive ? data.activeIcon : data.icon}
                        alt={`${data.name}_icon`}
                      />
                    </ListItemIcon> */}
                    <ListItemText
                      primary={data.name}
                      className="sidebar_text"
                    />
                  </ListItemButton>
                </ListItem>
              </List>
            );
          })}
        <List sx={{ padding: "0" }}>
          <ListItem
            onClick={() =>
              setIsModalOpen({ open: true, currentComponent: "logout" })
            }
            disablePadding
            sx={{
              margin: { xs: "10px  0", sm: "15px 0" },
              width: "unset",
            }}
          >
            <ListItemButton
              onClick={handleDrawerToggle}
              sx={{
                // borderRadius: "0 35px 35px 0",
                padding: { xs: "9px 15px 9px 15px", sm: "13px 20px 13px 25px" },
                backgroundColor: "",
              }}
            >
              {/* <ListItemIcon
                sx={{
                  minWidth: { xs: 30, sm: 35 },
                  width: { xs: "7px", sm: "11px" },
                  height: { xs: "14px", sm: "18px" },
                }}
              >
                <img src={LogoutBlue} alt={`logout_icon`} />
              </ListItemIcon> */}
              <ListItemText
                primary={"Logout"}
                className="sidebar_text"
                sx={{ color: "secondary.main" }}
              />
            </ListItemButton>
          </ListItem>
        </List>
        </Box>
      </Stack>
      <Modal
        modalOpen={isModalOpen}
        handleClose={() =>
          setIsModalOpen({ open: false, currentComponent: "" })
        }
      />
    </Box>
  );
};

export default Sidebar;
