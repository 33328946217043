import { DELETE, get, patch, post, put } from "../Web.request";

export const staffListHandler = (search, page, startDate, endDate) => {
  return get(
    `${process.env.REACT_APP_API_URL}/users/educator_data?search=${search}&start_date=${startDate}&end_date=${endDate}&page=${page}`
  );
};

export const deleteStaffById = (id) => {
  return DELETE(
    `${process.env.REACT_APP_API_URL}/users/delete_educator/${id}/`
  );
};

export const createStaffHandler = (payload) => {
  return post(
    `${process.env.REACT_APP_API_URL}/users/create_educator`,
    payload
  );
};

export const updateStaffHandler = (id, payload) => {
  return patch(
    `${process.env.REACT_APP_API_URL}/users/educator_update/${id}/`,
    payload
  );
};
