import {
  Grid,
  MenuItem,
  // Select,
  Stack,
  Typography,
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  Autocomplete,
  TextField,
  FormHelperText,
  Box,
  Switch,
  Select,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { getStandardAllList } from "../../Service/Standard.service";
import { personList } from "../../helper/Helper";
import { Button } from "../../components/commons";
import { IoIosAdd } from "react-icons/io";
import { IoClose } from "react-icons/io5";
import { Unknown } from "../../helper/Constant";
import { useNavigate, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import { bannedPerson } from "../../helper/intialValues";
import { childPersonValidation } from "../../helper/Validation";
import { getAllStudentDataApiHandler } from "../../Service/Student.service";
import {
  errorNotification,
  successNotification,
} from "../../helper/Notification";
import {
  modificationToPerson,
  updateModificationApihandler,
} from "../../Service/modificationPerson";
import { getAllGurdianDataHandler } from "../../Service/guardian.service";
import CSelect from "../../components/commons/CSelect";
import { FaPlus } from "react-icons/fa";
import { Modal } from "../../components/commons";

function BannedPerson({
  optOutFlag,
  clearFlag,
  optoutHandler,
  categoryList,
  categoryValue,
  handleCategoryChange,
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const navigationData = location.state;
  const [standardList, setStandardList] = useState([]);
  const [standard, setStandard] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorFlag, setErrorFlag] = useState(false);

  const [masterList, setMasterList] = useState([]);
  const [master, setMaster] = useState("");

  const [LinkedList, setLinkedList] = useState([]);
  const [Link, setLink] = useState("");

  const [selectMasterData, setSelectMasterData] = useState([]);
  const [selectLinkData, setSelectLinkData] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
    para: "",
    head: "",
  });

  const closeFlagHandler = () => {
    setIsModalOpen({
      open: false,
      currentComponent: "",
      para: "",
      head: "",
    })
  };

  useEffect(() => {
    if (navigationData?.editFlage) {
      getDataHandler(navigationData?.responseData);
    } else {
      formik.resetForm();
    }
  }, []);

  const getDataHandler = (data) => {
    console.log("da", data);
    console.log("Testing--*8*", data?.response?.LINKED);
    if (data?.response?.LINKED.length > 0) {
      const linkedPayload =
        data?.response?.LINKED &&
        data?.response?.LINKED.map((res) => {
          console.log("resssss", res);
          return {
            label: res.user_name ? res.user_name : "",
            id: res.id ? res.id : "",
            profilePicture: res.profile_image ? res.profile_image : "",
          };
        });
      setSelectLinkData(linkedPayload);
      const linkGuardianData = data?.response?.LINKED.map(
        (response) => response.id
      );
      formik.setFieldValue("guardians_ids", linkGuardianData);
    }
    if (data?.response?.MASTER.length > 0) {
      const masterPayload =
        data?.response?.MASTER &&
        data?.response?.MASTER.map((res) => {
          console.log("");
          return {
            label: res.user_name ? res.user_name : "",
            id: res.id ? res.id : "",
            profilePicture: res.profile_image ? res.profile_image : "",
          };
        });
      setMaster(masterPayload[0]);
      formik.setFieldValue("master_guardians_ids", [masterPayload[0].id]);
      setSelectMasterData(masterPayload);
    }

  };


  const deleteMasterData = (id) => {
    console.log("Id-->", id);
    const updatedMasterData = selectMasterData.filter((item) => item.id !== id);
    console.log("deleteMasterData", updatedMasterData);
    setSelectMasterData(updatedMasterData);
    setMaster("");
    const idData = updatedMasterData.map((item) => item.id);
    formik.setFieldValue("master_guardians_ids", idData);
  };

  const deleteLinkMasterData = (id) => {
    console.log("Id-->", id);
    const updatedLinkData = selectLinkData.filter((item) => item.id !== id);
    console.log("updatedLinkData", updatedLinkData);
    setSelectLinkData(updatedLinkData);
    const idData = updatedLinkData.map((res) => res.id);
    formik.setFieldValue("guardians_ids", idData);
  };

  useEffect(() => {
    getStandardListHandler();
    getLinkedDataHandler();
    getMasterDataHandler();
  }, []);

  const getLinkedDataHandler = async () => {
    const { count, data, message, success, totalPage } =
      await getAllGurdianDataHandler();
    if (success) {
      const linkedData =
        data &&
        data.map((res) => {
          return {
            label: res.username ? res.username : "",
            id: res.id ? res.id : "",
            profilePicture: res.profile_image ? res.profile_image : "",
          };
        });
      setLinkedList(linkedData);
    } else {
      setLinkedList([]);
    }
  };

  const getMasterDataHandler = async () => {
    const { count, data, message, success, totalPage } =
      await getAllGurdianDataHandler();
    if (success) {
      const masterData =
        data &&
        data.map((res) => {
          return {
            label: res.username ? res.username : "",
            id: res.id ? res.id : "",
            profilePicture: res.profile_image ? res.profile_image : "",
          };
        });
      setMasterList(masterData);
    } else {
      setMasterList([]);
    }
  };

  const getStandardListHandler = async () => {
    try {
      const { data, message, success } = await getStandardAllList();
      if (success) {
        setStandardList(data);
      } else {
        setStandardList([]);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const childPersonHandler = async () => {
    try {
      setLoading(true);
      const { data, message, success } = navigationData?.editFlage
        ? await updateModificationApihandler(
          navigationData.personId,
          formik.values
        )
        : await modificationToPerson(navigationData.personId, formik.values);
      if (success) {
        navigate("/modifications");
        successNotification(message);
      } else {
        errorNotification(message);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: bannedPerson,
    // validationSchema: childPersonValidation,
    onSubmit: childPersonHandler,
  });

  return (
    <>
      <Grid container>
        <Grid item xs={8}>
          <Stack flexDirection={"row"} gap={7}>
            <Box>
              <Stack justifyContent={"center"} alignItems={"center"} gap={2}>
                <Box sx={{ display: "flex" }}>
                  <IoClose className="closeicon"
                    onClick={() =>
                      setIsModalOpen({
                        open: true,
                        currentComponent: "deleteChild",
                        para: "Are you sure want to delete ?",
                        head: "Delete Other",
                        id: navigationData.personId,
                        name: "TRADESPERSON",
                      })} />
                  <img
                    src={
                      navigationData?.personImage
                        ? navigationData?.personImage
                        : ""
                    }
                    alt="Banned person image"
                    width={550}
                    height={600}
                  />
                </Box>
                <Typography variant="h2" className="Gilroy_Bold">
                  {navigationData?.personName ? navigationData?.personName : "-"}
                </Typography>
              </Stack>
            </Box>
            <Stack gap={4} mt={3}>
              <Stack sx={{ width: "100%", mt: 5 }}>
                <Box sx={{ width: "100%" }}>
                  <Stack
                    flexDirection={"row"}
                    justifyContent={"space-between"}
                    gap={3}
                  >
                    <Box>
                      <Grid container spacing={3}>
                        {/* <Grid item xs={12}>
                          <Stack
                            flexDirection={"row"}
                            alignItems={"center"}
                            justifyContent={"start"}
                          >
                            <Typography
                              fontSize={{ xs: "18px", sm: "38px" }}
                              fontWeight={"600"}
                            >
                              Opt Out :
                            </Typography>
                            &nbsp; &nbsp; &nbsp;
                            <Switch
                              checked={optOutFlag}
                              onChange={(event) =>
                                optoutHandler(event.target.checked)
                              }
                              color="secondary"
                            />
                          </Stack>
                        </Grid> */}
                        <Grid item xs={12}>
                          <Stack
                            flexDirection={"row"}
                            alignItems={"center"}
                            justifyContent={"space-between"}
                            gap={2}
                            sx={{ width: "500px" }}
                          >
                            <Typography
                              fontSize={{ xs: "18px", sm: "38px" }}
                              fontWeight={"600"}
                              sx={{ whiteSpace: "nowrap" }}
                            >
                              Category :
                            </Typography>
                            <CSelect
                              style={{ width: "300px" }}
                              selectHead="Select Category"
                              selectList={categoryList}
                              value={categoryValue}
                              onChange={handleCategoryChange}
                            />
                          </Stack>
                        </Grid>
                      </Grid>
                    </Box>
                  </Stack>
                </Box>
              </Stack>

              <Stack
                flexDirection={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
                gap={2}
                sx={{ width: "500px" }}
              >
                <Typography
                  fontSize={{ xs: "18px", sm: "38px" }}
                  fontWeight={"600"}
                  sx={{ whiteSpace: "nowrap" }}
                >
                  Linked :
                </Typography>
                <Autocomplete
                  options={LinkedList}
                  value={selectLinkData || []}
                  getOptionSelected={(option, value) =>
                    option.label === value.label
                  }
                  sx={{
                    width: "300px",
                    backgroundColor: "#fff",
                    borderRadius: "10px",
                    justifyContent: "flex-start"
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.label === value.label
                  }
                  onChange={(event, value) => {
                    setSelectLinkData(value);
                    if (value.length > 0) {
                      const ids = value.map((res) => res.id);
                      formik.setFieldValue("guardians_ids", ids);
                      // formik.setFieldValue("master_guardians_ids", ids);
                    } else {
                      // formik.setFieldValue("master_guardians_ids", []);
                      formik.setFieldValue("guardians_ids", []);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder={"1005"} />
                  )}
                  multiple
                />
              </Stack>
              <Grid item xs={12}>
                <Stack
                  flexDirection={"row"}
                  gap={{ xs: 2 }}
                  mt={2}
                  justifyContent={"center"}
                >
                  <Button
                    buttonName={"Save"}
                    loading={loading}
                    disabled={loading}
                    onClick={formik.handleSubmit}
                  />
                  <Button
                    buttonName="Exit"
                    color="white"
                    onClick={() => navigate("/modifications")}
                  />
                </Stack>
              </Grid>
            </Stack>

          </Stack>
          <Grid container spacing={3}>
            <Grid item xs={12}></Grid>
            {errorFlag && formik.errors.standard_id && (
              <FormHelperText>{formik.errors.standard_id}</FormHelperText>
            )}
            <Grid item xs={12}>
              <Stack
                flexDirection={"row"}
                gap={2}
                style={{ display: "flex", flexWrap: "wrap", marginTop: "10px" }}
              >
                {selectMasterData && selectMasterData.length > 0 ? (
                  selectMasterData.map((response) => {
                    return (
                      <Stack
                        justifyContent={"center"}
                        alignItems={"center"}
                        gap={1}
                      >
                        <Stack
                          flexDirection={"row"}
                          justifyContent={"space-between"}
                          width={"100%"}
                          alignItems={"center"}
                        >
                          <Typography variant="h4" className="Gilroy_Bold">
                            Master
                          </Typography>
                          <IoClose
                            onClick={() => deleteMasterData(response.id)}
                            style={{
                              fontSize: "45px",
                              cursor: "pointer",
                              backgroundColor: "white",
                              margin: "6px",
                              color: "black",
                            }}
                          />

                        </Stack>
                        <img
                          src={
                            response.profilePicture
                              ? response.profilePicture
                              : ""
                          }
                          alt=""
                          width={250}
                          height={300}
                        />
                        <Typography variant="h3" className="Gilroy_Bold">
                          {response.label ? response.label : "-"}
                        </Typography>
                        {/* <Button buttonName="Delete" /> */}
                        {/* <button className="deletebutton" onClick={() => deleteMasterData(response.id)}>Delete</button> */}
                      </Stack>
                    );
                  })
                ) : (
                  <> </>
                )}
                {selectLinkData && selectLinkData.length > 0 ? (
                  selectLinkData.map((response) => {
                    return (
                      <Stack
                        justifyContent={"center"}
                        alignItems={"center"}
                        gap={1}
                      >
                        <Stack
                          flexDirection={"row"}
                          justifyContent={"space-between"}
                          width={"100%"}
                          alignItems={"center"}
                        >
                          <Typography variant="h4" className="Gilroy_Bold">
                            Linked
                          </Typography>
                          <IoClose
                            onClick={() => deleteLinkMasterData(response.id)}
                            style={{
                              fontSize: "45px",
                              cursor: "pointer",
                              backgroundColor: "white",
                              margin: "6px",
                              color: "black",
                            }}
                          />
                        </Stack>
                        <img
                          src={
                            response.profilePicture
                              ? response.profilePicture
                              : ""
                          }
                          alt=""
                          width={250}
                          height={300}
                        />
                        <Typography variant="h3" className="Gilroy_Bold">
                          {response.label ? response.label : "-"}
                        </Typography>
                      </Stack>
                    );
                  })
                ) : (
                  <></>
                )}
              </Stack>
            </Grid>
          </Grid>
        </Grid>


      </Grid>

      <Modal
        modalOpen={isModalOpen}
        handleClose={() =>
          setIsModalOpen({
            open: false,
            currentComponent: "",
            para: "",
            head: "",
          })
        }
        closeFlag={closeFlagHandler}
      />
    </>
  );
}

export default BannedPerson;
