import { DELETE, get, patch, post, put } from "../Web.request";

export const deviceListHandler = (search, page, startDate, endDate) => {
    return get(`${process.env.REACT_APP_API_URL}/users/devices_data?search=${search}&start_date=${startDate}&end_date=${endDate}&page=${page}`)
};

export const deleteDeviceHandler = (id) => {
    return DELETE(`${process.env.REACT_APP_API_URL}/users/delete_devices/${id}/`)
};

export const deletemasterRoom = (id) => {
    return DELETE(`${process.env.REACT_APP_API_URL}/users/delete_standard/${id}`)
};

export const createDevice = (payload) => {
    return post(`${process.env.REACT_APP_API_URL}/users/create_devices`, payload)
};

export const updateDeviceHandler = (id, payload) => {
    return patch(`${process.env.REACT_APP_API_URL}/users/devices_update/${id}/`, payload);
};