import { DELETE, GET, get, patch, post, put } from "../Web.request";

export const standardListHandler = (search, page) => {
    return get(`${process.env.REACT_APP_API_URL}/users/standard_data?search=${search}&page=${page}`)
};

export const deleteStandardById = (id) => {
    return DELETE(`${process.env.REACT_APP_API_URL}/users/delete_standard/${id}/`)
};

export const createStandardHandler = (payload) => {
    return post(`${process.env.REACT_APP_API_URL}/users/create_standard`, payload);
};


// get All Standard list 

export const getStandardAllList = () => {
    return GET(`${process.env.REACT_APP_API_URL}/users/standard`)
}

export const updateStandardHandler = (id, payload) => {
    return patch(`${process.env.REACT_APP_API_URL}/users/standard_update/${id}/`, payload);
};

export const getMasetEnrollHours = (id, payload) => {
    return get(`${process.env.REACT_APP_API_URL}/users/master_enrolment_hours`);
};
