import { DELETE, get, patch, post, put } from "../Web.request";

export const addLocationHandler = (payload) => {
    return post(`${process.env.REACT_APP_API_URL}/users/create_location`,payload);
};

export const editLocationHandler = (id,payload) => {
    return patch (`${process.env.REACT_APP_API_URL}/users/location_update/${id}/`,payload);
};

export const deleteLocationHandler = (id) => {
    return DELETE(`${process.env.REACT_APP_API_URL}/users/delete_location/${id}/`)
};

export const getAllLocation = (search,page) => {
  return get(`${process.env.REACT_APP_API_URL}/users/location_data?search=${search}&page=${page}`);
};
