import React, { useState } from "react";
import { Box, Grid, Menu, Stack, Typography } from "@mui/material";
import {
  CgMenuLeft,
  FaUserLarge,
  PiBellSimpleRinging,
} from "../../helper/Icons";
import {
  HeaderAlert,
  HeaderNotification,
  HeaderSettings,
  Logo,
} from "../../helper/Constant";
import { SidebarList } from "../../helper/Helper";
import { Link, useLocation } from "react-router-dom";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import Cookies from "universal-cookie";

const Header = ({ handleDrawerToggle }) => {
  const location = useLocation();
  const isEdit = location?.state?.isEdit || false;
  const isView = location?.state?.isView || false;
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const cookies = new Cookies();
  const userData = {
    name: cookies.get("userName"),
    email: cookies.get("EMAIL"),
    profilePicture: cookies.get("PROFILE"),
  };

  const handleNotificationClick = () => {
    setIsNotificationOpen(true);
  };
  const handleAlertClick = () => {
    setIsAlertOpen(true);
  };

  const title = (() => {
    switch (location.pathname) {
      case "/add-person":
        return "Modification";
      default:
        return "";
    }
  })();

  return (
    <>
      <Stack
        flexDirection={"row"}
        justifyContent={"space-between"}
        width={"100%"}
        alignItems={"center"}
      >
        <Stack
          flexDirection={"row"}
          alignItems={"center"}
          gap={{ xs: "6px", sm: 1 }}
          justifyContent={"flex-start"}
        >
          <Stack
            sx={{
              backgroundColor: "white.main",
              display: { xs: "flex", md: "none" },
              cursor: "pointer",
              boxShadow: "0px 0px 5px 0px rgba(74, 88, 236, 0.20)",
              width: { xs: "35px", sm: "40px" },
              height: { xs: "35px", sm: "40px" },
              borderRadius: "8px",
            }}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <CgMenuLeft
              className="menu_icon"
              style={{ color: "#000" }}
              onClick={handleDrawerToggle}
            />
          </Stack>
          {SidebarList.map((data, index) => {
            return (
              location.pathname === data.path && (
                <Box key={index}>
                  <Stack
                    key={index}
                    flexDirection={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    gap={1}
                    sx={{ backgroundColor: "blue.main", color: "white.main", padding: "10px 40px" }}
                  >
                    <Typography
                      fontSize={{ xs: "25px", sm: "45px" }}
                      className="Gilroy_Bold"
                      lineHeight={1}
                    >
                      {data.name}
                    </Typography>
                  </Stack>
                </Box>
              )
            );
          })}
          {title && (
            <Stack
              flexDirection={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
              gap={1}
            >
              <Typography
                fontSize={{ xs: "25px", sm: "45px" }}
                className="Gilroy_Bold"
                lineHeight={1}
              >
                {title}
              </Typography>
            </Stack>
          )}
        </Stack>
        <img src={Logo} alt="main_logo" width={"200px"} />
      </Stack>
    </>
  );
};

export default Header;
