import { Box, TablePagination } from '@mui/material'
import React, { useEffect } from 'react'

const Pagination = ({ pageNumber, count, pageChangeHandler, perPage }) => {
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [countNumber, setCountNumber] = React.useState(0);
  useEffect(() => {
    if (pageNumber) {
      setPage(pageNumber)
    }
    if (count) {
      setCountNumber(count)
    }
  }, [pageNumber, count])

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
    pageChangeHandler(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <Box>
      <TablePagination
        component="div"
        count={countNumber}
        page={page - 1}
        onPageChange={handleChangePage}
        rowsPerPage={perPage ? perPage : rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[]}
      />
    </Box>
  )
}

export default Pagination