import React, { useState } from "react";
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { CloseIcon } from "../../helper/Icons";
import { Button } from "../commons";
import { deleteStandardById } from "../../Service/Standard.service";
import { deleteClassById } from "../../Service/class.service";
import { deleteStudentById, deleteStudentByIdNew } from "../../Service/Student.service";
import { errorNotification, successNotification } from "../../helper/Notification";
import { deleteGuardianById } from "../../Service/guardian.service";
import { deleteSubjectHandler } from "../../Service/Subject.service";
import { deleteStaffById } from "../../Service/Staff.service";
import { deleteTradesPersonById } from "../../Service/Tradesperson.service";
import { deleteLocationHandler } from "../../Service/locations.service";
import { deleteDeviceHandler, deletemasterRoom } from "../../Service/device.service";

const Delete = ({ handleClose, modalOpen, closeFlag, isDeleteNewChild, listUpdate }) => {
  const response = modalOpen;
  const [loading, setLoading] = useState(false);

  const agreeHandler = async () => {
    if (response.name === "STANDARD") {
      const { data, message, success } = await deleteStandardById(response.id);
      if (success) {
        successNotification(message);
        closeFlag();
        handleClose();
      } else {
        errorNotification(message);
        closeFlag();
        handleClose();
      }
    }
    if (response.name === "CLASS") {
      const { data, message, success } = await deleteClassById(response.id);
      if (success) {
        successNotification(message);
        closeFlag();
        handleClose();
      } else {
        errorNotification(message);
        closeFlag();
        handleClose();
      }
    }

    if (response.name === "STUDENT") {
      if (isDeleteNewChild == true) {
        const { data, message, success } = await deleteStudentByIdNew(response.id);
        if (success) {
          successNotification(message);
          closeFlag();
          handleClose();
        } else {
          errorNotification(message);
          closeFlag();
          handleClose();
        }
      } else {
        const { data, message, success } = await deleteStudentById(response.id);
        if (success) {
          successNotification(message);
          closeFlag();
          handleClose();
        } else {
          errorNotification(message);
          closeFlag();
          handleClose();
        }
      }
    }
    // GUARDIAN
    if (response.name === "GUARDIAN") {
      if (isDeleteNewChild == true) {
        const { data, message, success } = await deleteStudentByIdNew(response.id);
        if (success) {
          successNotification(message);
          closeFlag();
          handleClose();
        } else {
          errorNotification(message);
          closeFlag();
          handleClose();
        }
      } else {
        const { data, message, success } = await deleteGuardianById(response.id);
        if (success) {
          successNotification(message);
          closeFlag();
          handleClose();
        } else {
          errorNotification(message);
          closeFlag();
          handleClose();
        }
      }

    }
    if (response.name === "SUBJECT") {
      const { data, message, success } = await deleteSubjectHandler(response.id);
      if (success) {
        successNotification(message);
        closeFlag();
        handleClose();
      } else {
        errorNotification(message);
        closeFlag();
        handleClose();
      }
    }
    // STAFF
    if (response.name === "STAFF") {
      if (isDeleteNewChild == true) {
        const { data, message, success } = await deleteStudentByIdNew(response.id);
        if (success) {
          successNotification(message);
          closeFlag();
          handleClose();
        } else {
          errorNotification(message);
          closeFlag();
          handleClose();
        }
      } else {
        const { data, message, success } = await deleteStaffById(response.id);
        if (success) {
          successNotification(message);
          closeFlag();
          handleClose();
        } else {
          errorNotification(message);
          closeFlag();
          handleClose();
        }
      }

    }
    // TRADESPERSON
    if (response.name === "TRADESPERSON") {
      if (isDeleteNewChild == true) {
        const { data, message, success } = await deleteStudentByIdNew(response.id);
        if (success) {
          successNotification(message);
          closeFlag();
          handleClose();
        } else {
          errorNotification(message);
          closeFlag();
          handleClose();
        }
      } else {
        const { data, message, success } = await deleteTradesPersonById(response.id);
        if (success) {
          successNotification(message);
          closeFlag();
          handleClose();
        } else {
          errorNotification(message);
          closeFlag();
          handleClose();
        }
      }

    }

    if (response.name === "LOCATION") {
      const { data, message, success } = await deleteLocationHandler(response.id);
      if (success) {
        successNotification(message);
        closeFlag();
        handleClose();
      } else {
        errorNotification(message);
        closeFlag();
        handleClose();
      }
    }
    // deleteDeviceHandler
    // DEVICE
    if (response.name === "DEVICE") {
      const { data, message, success } = await deleteDeviceHandler(response.id);
      if (success) {
        successNotification(message);
        closeFlag();
        handleClose();
      } else {
        errorNotification(message);
        closeFlag();
        handleClose();
      }
    }

    if (response.name === "DELETEROOMSETTING") {
      const { data, message, success } = await deletemasterRoom(response.id);
      if (success) {
        successNotification(message);
        closeFlag();
        handleClose();
        listUpdate();
      } else {
        errorNotification(message);
        closeFlag();
        handleClose();
      }
    }
  };



  const disAgreeHandler = () => {
    handleClose();
  };

  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
        {modalOpen.head}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Box width={{ sm: "400px" }}>
          <Typography fontSize={"18px"}>{modalOpen.para}</Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          buttonName="No"
          size="small"
          color="white"
          onClick={() => disAgreeHandler()}
        />
        <Button buttonName="Yes" size="small" onClick={() => agreeHandler()} />
      </DialogActions>
    </>
  );
};

export default Delete;
