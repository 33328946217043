import * as Yup from "yup";

export const loginValidation = Yup.object({
  username: Yup.string().trim().required("UserName is required"),
  password: Yup.string().trim().required("Password is required"),
});

export const standardValidation = Yup.object({
  name: Yup.string().trim().required("Name is required"),
});

export const classValidation = Yup.object({
  name: Yup.string().trim().required("Name is required"),
  standard: Yup.string().trim().required("Standard is required"),
});

export const studentValidation = Yup.object({
  username: Yup.string().trim().required("Username is required"),
  first_name: Yup.string().trim().required("Firstname is required"),
  last_name: Yup.string().trim().required("Lastname is required"),
  contact_number: Yup.string()
    .trim()
    .matches(/^\d+$/, "Contact number must contain only numbers")
    .length(10, "Contact number must be 10 digits")
    .required("Contact number is required"),
  email: Yup.string()
    .trim()
    .matches(
      /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
      "Invalid email format"
    )
    .required("Email is required"),
  gender: Yup.string().trim().required("Gender is required"),
  dob: Yup.string().trim().required("Birthdate is required"),
  standerd_with_division: Yup.string().trim().required("Class is required"),
  standard_id: Yup.string().trim().required("standard is required"),
  profile_image: Yup.string().trim().required("profile is required"),
});

export const guardianValidation = Yup.object({
  username: Yup.string().trim().required("Username is required"),
  first_name: Yup.string().trim().required("First Name is required"),
  last_name: Yup.string().trim().required("Last Name is required"),
  contact_number: Yup.string()
    .trim()
    .matches(/^\d+$/, "Contact number must contain only numbers")
    .length(10, "Contact number must be 10 digits")
    .required("Contact number is required"),
  email: Yup.string()
    .trim()
    .matches(
      /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
      "Invalid email format"
    )
    .required("Email is required"),
  profile_image: Yup.string().trim().required("profile is required"),
  gender: Yup.string().trim().required("Gender is required"),
  dob: Yup.string().trim().required("Birthdate is required"),
  // need to keep this commented
  //   is_blacklisted: false,
  //   opt_out: true,
});

export const subjectValidation = Yup.object({
  subject_name: Yup.string().trim().required("SubjectName is required")
});

export const staffValidation = Yup.object({
  username: Yup.string().trim().required("UserName is required"),
  first_name: Yup.string().trim().required("FirstName is required"),
  last_name: Yup.string().trim().required("Last Name is required"),
  contact_number: Yup.string().trim()
    .matches(/^\d+$/, "Contact number must contain only numbers")
    .length(10, "Contact number must be 10 digits")
    .required("Contact number is required"),
  email: Yup.string()
    .trim()
    .matches(
      /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
      "Invalid email format"
    )
    .required("Email is required"),
  profile_image: Yup.string().trim().required("Profile is required"),
  gender: Yup.string().trim().required("Gender is required"),
  dob: Yup.string().trim().required("Birthdate is required"),
  is_blacklisted: Yup.boolean().required("BlackListed is required"),
  opt_out: Yup.boolean().required("opt_out is required"),
  educator_subject: Yup.string().trim().required("Subject is required")
});

export const addTreadPersonValidation = Yup.object({
  username: Yup.string().trim().required("UserName is required"),
  first_name: Yup.string().trim().required("FirstName is required"),
  last_name: Yup.string().trim().required("LastName is required"),
  contact_number: Yup.string().trim()
    .matches(/^\d+$/, "Contact number must contain only numbers")
    .length(10, "Contact number must be 10 digits")
    .required("Contact number is required"),
  email: Yup.string().trim().matches(
    /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
    "Invalid email format"
  )
    .required("Email is required"),
  profile_image: Yup.string().trim().required("Profile is required"),
  gender: Yup.string().trim().required("Gender is required"),
  dob: Yup.string().trim().required("Birthdate is required"),
  is_blacklisted: Yup.boolean().required("BlackListed is required"),
  opt_out: Yup.boolean().required("opt_out is required"),
});

export const addLocationValidation = Yup.object({
  location: Yup.string().trim().required("Location is required")
});


export const deviceValidation = Yup.object({
  location: Yup.string().trim().required("Location is required"),
  device_name: Yup.string().trim().required("Device Name is required"),
  ip_address: Yup.string().trim().required("Ip Address Name is required"),
  rtsp_url: Yup.string().trim().required("Rtsp Url Name is required"),
  fps: Yup.string()
    .matches(/^[0-9]+$/, "Only numbers are acceptable")
    .trim()
    .required("Fps is required"),
  device_port: Yup.string()
    .matches(/^[0-9]+$/, "Only numbers are acceptable")
    .trim()
    .required("Device Port is required"),
  device_username: Yup.string().trim().required("Ip Address Name is required"),
  device_password: Yup.string().trim().required("Device Password is required")
});

export const educatorPersonValidation = Yup.object({
  standard_id: Yup.string().trim().required("room Name is required")
})

export const guardianPersonValidation = Yup.object({
  // student_ids:Yup.array().min(1, "At least Master is required"),
  // master_student_ids:Yup.array().min(1, "At least Master is required")
});

export const childPersonValidation = Yup.object({
  // standard_id:Yup.string().trim().optional("room Name is required"),
  // guardians_ids: Yup.array().min(1, "At least Guardians is required"),
  // master_guardians_ids: Yup.array().min(1, "At least Guardians is required")
});