import { DELETE, get, patch, post, put } from "../Web.request";

export const deleteGuardianById = (id) => {
  return DELETE(
    `${process.env.REACT_APP_API_URL}/users/map_to/guardian__mep_delete/${id}/`
  );
};

export const updateGuardianHandler = (id, payload) => {
  return patch(
    `${process.env.REACT_APP_API_URL}/users/guardian_update/${id}/`,
    payload
  );
};

export const createGurdianHandler = (payload) => {
  return post(
    `${process.env.REACT_APP_API_URL}/users/create_guardian`,
    payload
  );
};

export const getGurdianHandler = (search) => {
  return get(
    `${process.env.REACT_APP_API_URL}/users/guardian_data?search=${search}`
  );
};

// get gurdianDetails By student Id

export const gurdianStudentId = (id) => {
  return get(
    `${process.env.REACT_APP_API_URL}/users/map_to/guardian_data?search=${id}`
  );
};

export const getGurdianDetailById = (id) => {
  return get(`${process.env.REACT_APP_API_URL}/users/guardian?search=${id}`);
};

export const getAllGurdianDataHandler = () =>{
  return get(`${process.env.REACT_APP_API_URL}/users/guardian`);
};

export const mapGurdianHandler = (payload) => {
  return post(
    `${process.env.REACT_APP_API_URL}/users/map_to/guardian`,
    payload
  );
};
