import React from 'react'
import { Button, CircularProgress, styled } from '@mui/material'
const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});
const CommonButton = (props) => {
  const { fileupload,onClick, buttonName, fullWidth, variant, color, size, startIcon, endIcon, disabled, style, className,loading } = props
  return (
    <>
      <Button
        variant={variant ? variant : "contained"}
        color={color ? color : 'primary'}
        size={size ? size : "medium"}
        onClick={onClick}
        fullWidth={fullWidth}
        startIcon={startIcon}
        endIcon={endIcon}
        disabled={loading || disabled}
        sx={style}
        className={className}
      >
        {
          loading ? (
            <CircularProgress size={17} color="inherit" />
          ) : (
            buttonName
          )
        }
        {
          fileupload &&
          <VisuallyHiddenInput type="file" onClick={()=>console.log("Clicked")}/>
        }
      </Button>
    </>
  )
}

export default CommonButton