import { DELETE, get, patch, post, put } from "../Web.request";


export const addSubjectHandler = (payload) =>{
    return post(`${process.env.REACT_APP_API_URL}/users/create_subjects`,payload);
};

export const editSubjectHandler = (id,payload) =>{
    return patch(`${process.env.REACT_APP_API_URL}/users/subjects_update/${id}/`,payload);
};

export const deleteSubjectHandler = (id) =>{
    return DELETE(`${process.env.REACT_APP_API_URL}/users/delete_subjects/${id}/`)
};

export const subjectListHandler = (search,page,startDate,endDate) =>{
    return get(`${process.env.REACT_APP_API_URL}/users/subjects_data?search=${search}&start_date=${startDate}&end_date=${endDate}&page=${page}`)
};

export const getAllSubject = () =>{
    return get(`${process.env.REACT_APP_API_URL}/users/subjects`)
};