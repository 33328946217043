import React from 'react'
import { Box, DialogActions, DialogContent, DialogTitle, Grid, IconButton } from '@mui/material'
import { CloseIcon } from '../../../helper/Icons'
import { Button, Input } from '../../commons'

const AddDevice = ({ handleClose, modalOpen,closeFlag }) => {
    return (
        <>
            <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
                {modalOpen?.isEdit === true ? 'Edit' : 'Add'} Device
            </DialogTitle>
            <IconButton aria-label="close" onClick={handleClose}
                sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500], }}>
                <CloseIcon />
            </IconButton>
            <DialogContent>
                <Box width={{ sm: '400px' }}>
                    <Grid container gap={2}>
                        <Grid item xs={12}>
                            <Input labelinput='RTSP Link' />
                        </Grid>
                        <Grid item xs={12}>
                            <Input labelinput='Camera ID' />
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button buttonName='Cancel' size='small' color='white' onClick={handleClose} />
                <Button buttonName={modalOpen?.isEdit === true ? 'Save' : 'Add'} size='small' onClick={handleClose} />
            </DialogActions>
        </>
    )
}

export default AddDevice